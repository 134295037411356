import React from 'react'
import { Avatar, Box, Typography } from '@mui/material'

export function UserAvatar({ user, sx = null }) {
  let defaultAvatarURL
  if (user?.gender === 'female') {
    defaultAvatarURL = '/default-female-avatar.png'
  } else {
    defaultAvatarURL = '/default-male-avatar.png'
  }

  return <Avatar src={user?.avatar_url || defaultAvatarURL} sx={sx} />
}

export function UserAvatarWithName({
  user,
  bold = false,
  avatarSize = 50,
  boxWidth = avatarSize + 10,
  fontSize = '0.7rem',
  maxNameLength = 8,
}) {
  let defaultAvatarURL
  if (user?.gender === 'female') {
    defaultAvatarURL = '/default-female-avatar.png'
  } else {
    defaultAvatarURL = '/default-male-avatar.png'
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: boxWidth,
      }}
    >
      <Avatar
        alt={`${user.name} ${user.surname}`}
        src={user?.avatar_url || defaultAvatarURL}
        sx={{
          width: avatarSize,
          height: avatarSize,
          border: '1px solid black',
          backgroundColor: 'white',
        }}
      />
      <Typography
        variant="caption"
        sx={{
          fontSize,
          mt: 0.5,
          width: 'max-content',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          margin: '0 auto',
          fontWeight: bold ? 'bold' : 'normal',
        }}
      >
        {user.name.length > maxNameLength ? `${user.name.substring(0, maxNameLength-2)}...` : user.name}
      </Typography>
      <Typography
        variant="caption"
        sx={{
          fontSize,
          mt: 0.5,
          width: 'max-content',
          textAlign: 'center',
          whiteSpace: 'nowrap',
          margin: '0 auto',
          fontWeight: bold ? 'bold' : 'normal',
        }}
      >
        {user.surname && user.surname.length > maxNameLength
          ? `${user.surname.substring(0, maxNameLength-2)}...`
          : user.surname || <br />}
      </Typography>
    </Box>
  )
}
