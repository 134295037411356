import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material'

import { Info } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { LocalizationProvider } from '@mui/x-date-pickers'

import {
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'

import {
  useAddUserToTournament,
  useAddUserToTournamentWaitlist,
  useRemoveUserFromTournament,
  useRemoveUserFromTournamentWaitlist,
  useStartTournament,
  useUpdateTournament,
  usePseudoDeleteTournament,
  useTournamentRoundNumber,
} from 'hooks/api/tournamentHooks'

import { useOrganization } from 'hooks/api/organizationHooks'

import { isAdmin, getUtcTime } from 'utils'
import { useCurrentUser } from 'hooks/cookies'

import { EasyRegisterDialog } from 'components/EasyRegisterDialog'
import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import {
  TournamentRegisteredSinglePlayers,
  TournamentRegisteredSingleWaitlistPlayers,
  TournamentRegisteredLeftPlayers,
} from 'components/TournamentRegisteredPlayers/TournamentRegisteredPlayers'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'
import { DeleteTournamentDialog } from 'components/DeleteTournamentDialog/DeleteTournamentDialog'
import { CustomDatePicker, CustomTimePicker } from 'components/CustomPicker'
import { TournamentInfo } from 'components/TournamentInfo/TournamentInfo'
import { LocationBlock } from 'components/LocationBlock/LocationBlock'
import { LocationSelector } from 'components/LocationSelector/LocationSelector'
import { LocationCourtsSelector } from 'components/LocaitonCourts/LocationCourtsSelector'
import { RegisterUserToTournamentDialog } from 'components/RegisterUserToTournamentDialog/RegisterUserToTournamentDialog'
import { MaxUsersSelector } from 'components/MaxUsersSelector/MaxUsersSelector'
import { PointsToPlaySelector } from 'components/PointsToPlaySelector/PointsToPlaySelector'

/* eslint-disable react/jsx-props-no-spreading */
export function AmericanoSingleCreated({ data: tournamentData, mutate }) {
  const tournamentType = 'americano_single'
  const navigate = useNavigate()

  const { themeMode } = useThemeSwitcher()

  const {
    organization_id,
    organization_name,
    users,
    waitlist_users,
    left_users,
  } = tournamentData

  const { data: organizationData } = useOrganization(organization_id)
  const { data: roundNumber } = useTournamentRoundNumber(tournamentType)

  const { tournamentId } = useParams()
  const tournamentIdInt = parseInt(tournamentId, 10)
  const [currentUser] = useCurrentUser()

  const admin = isAdmin(currentUser)
  const organizationAdmin =
    currentUser?.administered_organizations?.data?.length >= 1
  const adminOrOrganizationAdmin = admin || organizationAdmin

  const [easyRegisterOpen, setEasyRegisterOpen] = useState(false)
  const [easyRegisterWaitlistOpen, setEasyRegisterWaitlistOpen] =
    useState(false)
  const [selectUserOpen, setSelectUserOpen] = useState(false)
  const [selectWaitlistUserOpen, setSelectWaitlistUserOpen] = useState(false)

  const [courts, setCourts] = useState(tournamentData.courts || [])
  const [openStartTournamentModal, setOpenStartTournamentModal] =
    useState(false)

  const [openDeleteTournamentModal, setOpenDeleteTournamentModal] =
    useState(false)

  const [errorMessage, setErrorMessage] = useState('')

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      readOnly: true,

      location_id: tournamentData?.location?.id || null,
      location: tournamentData?.location || null,
      organization: organizationData || null,
      hasPermanentLocation: organizationData?.has_permanent_location,

      name: tournamentData?.name || '',
      type: tournamentData?.type || tournamentType,
      maxUsers: tournamentData?.max_users || 0,
      americanoMaxScore: tournamentData?.americano_max_score || 0,
      startTime: tournamentData?.start_time
        ? tournamentData?.start_time
        : getUtcTime(Date.now()).format(),
      endTime: tournamentData?.end_time
        ? tournamentData?.end_time
        : getUtcTime(Date.now()).format(),
      description: tournamentData?.description || '',
    },
  })

  useEffect(() => {
    setValue('organization', organizationData || null)
  }, [organizationData])

  const collectChanges = () => {
    const currentValues = {
      name: watch('name'),
      americano_max_score: watch('americanoMaxScore'),
      start_time: watch('startTime'),
      end_time: watch('endTime'),
      description: watch('description'),
      max_users: watch('maxUsers'),
      location_id: watch('locationId'),
      courts,
    }

    const changes = Object.keys(currentValues).reduce((acc, key) => {
      if (currentValues[key] !== tournamentData[key]) {
        acc[key] = currentValues[key]
      }
      return acc
    }, {})

    return changes
  }

  const { trigger: updateTournamentTrigger } = useUpdateTournament()
  const { trigger: pseudoDeleteTournamentTrigger } = usePseudoDeleteTournament()
  const { trigger: startAmericanoTournamentTrigger } = useStartTournament()
  const { trigger: addUserToTournamentTrigger } = useAddUserToTournament()
  const { trigger: addUserToTournamentWaitlistTrigger } =
    useAddUserToTournamentWaitlist()
  const { trigger: removeUserFromTournamentTrigger } =
    useRemoveUserFromTournament()
  const { trigger: removeUserFromTournamentWaitlistTrigger } =
    useRemoveUserFromTournamentWaitlist()

  const handleStartTournament = () => {
    startAmericanoTournamentTrigger(
      {
        id: tournamentIdInt,
      },
      {
        onSuccess: () => mutate(),
        onError: (data) => {
          if (data.info.error.includes('tournament preset with ')) {
            setErrorMessage(
              `Incorrect number of courts for the registered number of players.
              Please add or remove some courts on the edit page and try again.`
            )
          }
        },
      }
    )
  }

  const handleAddUserToTournament = (
    user = currentUser,
    anAdminAddedThePlayer = false
  ) => {
    if (!user) {
      setEasyRegisterOpen(true)
      return
    }

    addUserToTournamentTrigger(
      {
        tournament_id: tournamentIdInt,
        user_id: Number(user.id),
        an_admin_added_the_player: anAdminAddedThePlayer,
      },
      {
        onSuccess: () => mutate(),
        onError: (data) => setErrorMessage(data.info.error),
      }
    )
  }

  const handleAddUserToTournamentWaitlist = (
    user = currentUser,
    anAdminAddedThePlayer = false
  ) => {
    if (!user) {
      setEasyRegisterWaitlistOpen(true)
      return
    }

    addUserToTournamentWaitlistTrigger(
      {
        tournament_id: tournamentIdInt,
        user_id: Number(user.id),
        an_admin_added_the_player: anAdminAddedThePlayer,
      },
      {
        onSuccess: () => mutate(),
        onError: (data) => setErrorMessage(data.info.error),
      }
    )
  }

  const handleRemoveUserFromTournament = (user) => {
    removeUserFromTournamentTrigger(
      {
        tournament_id: tournamentIdInt,
        user_id: user.id,
      },
      {
        onSuccess: () => mutate(),
        onError: (data) => setErrorMessage(data.info.error),
      }
    )
  }

  const handleRemoveUserFromTournamentWaitlist = (user) => {
    removeUserFromTournamentWaitlistTrigger(
      {
        tournament_id: tournamentIdInt,
        user_id: user.id,
      },
      {
        onSuccess: () => mutate(),
        onError: (data) => setErrorMessage(data.info.error),
      }
    )
  }

  const handleUpdateTournament = () => {
    const changes = collectChanges()

    updateTournamentTrigger(
      {
        id: tournamentIdInt,
        ...changes,
      },
      {
        onSuccess: () => {
          mutate()
          setValue('readOnly', true)
        },
        onError: (data) => setErrorMessage(data.info),
      }
    )
  }

  const handleCancelUpdate = () => {
    reset()
  }

  const handlePseudoDeleteTournament = () => {
    pseudoDeleteTournamentTrigger(
      {
        id: tournamentIdInt,
      },
      {
        onSuccess: () => {
          navigate('/')
        },
      }
    )
  }

  const handleOpenStartTournamentModal = () => {
    setOpenStartTournamentModal(true)
  }

  const handleCloseStartTournamentModal = () => {
    setOpenStartTournamentModal(false)
  }

  const handleConfirmStartTournament = () => {
    handleStartTournament()
    setOpenStartTournamentModal(false)
  }

  const handleOpenDeleteTournamentModal = () => {
    setOpenDeleteTournamentModal(true)
  }

  const handleCloseDeleteTournamentModal = () => {
    setOpenDeleteTournamentModal(false)
  }

  const handleConfirmDeleteTournament = () => {
    handlePseudoDeleteTournament()
    setOpenDeleteTournamentModal(false)
  }

  const isUserRegistered = currentUser
    ? users.some((u) => u.id === currentUser.id)
    : false

  const tournamentAddress = tournamentData?.location?.name || organization_name
  const domain = process.env.REACT_APP_DOMAIN
  // mock for messagers
  const url = ' '
  const emptySpaceNumber = Math.max(watch('maxUsers') - users.length, 0)
  const descriptionText = watch('description')
    ? `\n${watch('description')}\n`
    : ''

  const userNames = users
    .map((user) => `🎾 ${user.name} ${user.surname}`)
    .join('\n')

  const waitlistNames =
    waitlist_users?.length > 0
      ? `
      waitlist
${waitlist_users?.map((user) => `🎾 ${user.name} ${user.surname}`).join('\n')}
`
      : `
      waitlist
🎾
`

  const emptySlots =
    emptySpaceNumber > 0 ? Array(emptySpaceNumber).fill('🎾 ').join('\n') : ''

  const title = `*Americano Single Tournament*

📍 ${tournamentAddress} 🎾
📆 ${getUtcTime(watch('startTime')).format('dddd DD MMMM')}      
⏰ ${getUtcTime(watch('startTime')).format('hh:mm a')} - ${getUtcTime(
    watch('endTime')
  ).format('hh:mm a')}
${descriptionText}
${userNames}
${emptySlots}
${waitlistNames}

👇🏼Join the tournament👇🏼
${domain}/tournament/${tournamentId}`

  const pointTime = 0.5
  const changeCourtsTime = 2
  let est_time = 0
  let estimatedTimeString = ''
  let singleCourtsNumber = 0
  let doubleCourtsNumber = 0

  for (let i = 0; i < courts.length; i += 1) {
    if (courts[i].size === 'single') {
      singleCourtsNumber += 1
    } else {
      doubleCourtsNumber += 1
    }
  }

  const defaultMaxUsers =
    JSON.stringify(tournamentData.courts) === JSON.stringify(courts)
      ? tournamentData.max_users
      : singleCourtsNumber * 2 + doubleCourtsNumber * 4

  let courtsString = `${doubleCourtsNumber}courts`
  if (singleCourtsNumber > 0 && doubleCourtsNumber > 0) {
    courtsString += `_${singleCourtsNumber}singlecourts`
  }

  let maxUsersOptions = []

  if (roundNumber) {
    if (roundNumber.courts[courtsString]) {
      maxUsersOptions = Object.keys(roundNumber.courts[courtsString].players)
      if (roundNumber.courts[courtsString].players[watch('maxUsers')]) {
        est_time =
          roundNumber.courts[`${courtsString}`].players[
            `${watch('maxUsers')}`
          ] *
          (Number(watch('maxScore')) * pointTime + changeCourtsTime)

        switch (Math.floor(est_time / 60)) {
          case 0:
            estimatedTimeString = `${Math.floor(est_time)} min`
            break
          case 1:
            estimatedTimeString = `${Math.floor(
              est_time / 60
            )} hour ${Math.floor(est_time % 60)} min`
            break
          default:
            estimatedTimeString = `${Math.floor(
              est_time / 60
            )} hours ${Math.floor(est_time % 60)} min`
            break
        }
      }
    }
  }

  return (
    <MainWrapper
      maxWidth="md"
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      <FormProvider
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        watch={watch}
        reset={reset}
        setValue={setValue}
      >
        <FormControl
          component="form"
          onSubmit={handleSubmit(handleUpdateTournament)}
          fullWidth
        >
          {watch('readOnly') ? (
            <Grid container spacing={2} mb={4}>
              <Grid item xs={12} sx={{ textAlign: 'center' }} mb={2}>
                <Typography variant="h5">{watch('name')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TournamentInfo
                  tournamentData={tournamentData}
                  organizationData={organizationData}
                  themeMode={themeMode}
                />
              </Grid>

              {adminOrOrganizationAdmin && (
                <>
                  <Grid
                    item
                    md={4}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />
                  <Grid item xs={12} md={4}>
                    <Button
                      fullWidth
                      onClick={() => setValue('readOnly', false)}
                      type="button"
                      startIcon={<EditIcon />}
                      variant="contained"
                    >
                      Edit
                    </Button>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />
                </>
              )}

              <Grid
                item
                xs={12}
                sx={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h5">Share the tournament</Typography>
              </Grid>
              <Grid
                container
                spacing={1}
                item
                xs={12}
                sx={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Grid item>
                  <WhatsappShareButton url={url} title={title}>
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </Grid>
                <Grid item>
                  <TelegramShareButton url={url} title={title}>
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>
                </Grid>
                <Grid item>
                  <TwitterShareButton url={url} title={title}>
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography variant="h5">Registered Players</Typography>
              </Grid>

              <Grid item xs={12} md={12}>
                <TournamentRegisteredSinglePlayers
                  players={users}
                  waitlistPlayers={waitlist_users}
                  maxUsers={watch('maxUsers')}
                  isAdmin={adminOrOrganizationAdmin}
                  currentUser={currentUser}
                  tournamentId={tournamentIdInt}
                  organizationId={organization_id}
                  exceptionIds={[
                    ...users.map((u) => u.id),
                    ...waitlist_users.map((u) => u.id),
                  ]}
                  setSelectUserOpen={setSelectUserOpen}
                  isUserRegistered={isUserRegistered}
                  handleAddUserToTournament={handleAddUserToTournament}
                  handleRemoveUserFromTournament={
                    handleRemoveUserFromTournament
                  }
                  themeMode={themeMode}
                />
              </Grid>

              {watch('maxUsers') === users.length && (
                <>
                  <Grid item xs={12} mt={1} sx={{ textAlign: 'center' }}>
                    <Typography variant="h5">Waitlist</Typography>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TournamentRegisteredSingleWaitlistPlayers
                      waitlistPlayers={waitlist_users}
                      isAdmin={adminOrOrganizationAdmin}
                      currentUser={currentUser}
                      tournamentId={tournamentIdInt}
                      organizationId={organization_id}
                      exceptionIds={[
                        ...users.map((u) => u.id),
                        ...waitlist_users.map((u) => u.id),
                      ]}
                      setSelectWaitlistUserOpen={setSelectWaitlistUserOpen}
                      isUserRegistered={isUserRegistered}
                      handleAddUserToTournamentWaitlist={
                        handleAddUserToTournamentWaitlist
                      }
                      handleRemoveUserFromTournamentWaitlist={
                        handleRemoveUserFromTournamentWaitlist
                      }
                      themeMode={themeMode}
                    />
                  </Grid>
                </>
              )}

              {left_users && left_users.length > 0 && (
                <>
                  <Grid item xs={12} mt={1} sx={{ textAlign: 'center' }}>
                    <Typography variant="h5">Players Left</Typography>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TournamentRegisteredLeftPlayers
                      leftPlayers={left_users}
                      themeMode={themeMode}
                    />
                  </Grid>
                </>
              )}

              {adminOrOrganizationAdmin && (
                <>
                  <Grid
                    item
                    md={4}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />
                  <Grid item xs={12} md={4}>
                    <Button
                      style={{ width: '100%' }}
                      onClick={handleOpenStartTournamentModal}
                      type="button"
                      variant="contained"
                    >
                      Start tournament
                    </Button>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                  />
                </>
              )}
            </Grid>
          ) : (
            // TODO: move to separate component
            <>
              <Grid
                item
                xs={12}
                mt={{ xs: 2, md: 4 }}
                sx={{ textAlign: 'center' }}
              >
                <Typography variant="h5">Edit Tournament</Typography>
              </Grid>
              <Grid container spacing={2} mt={2} mb={4} alignItems="flex-start">
                <Grid item xs={12}>
                  <TextField
                    required={!watch('readOnly')}
                    style={{ width: '100%' }}
                    error={!!errors.name}
                    label="Tournament name"
                    InputProps={{
                      readOnly: watch('readOnly'),
                    }}
                    {...register('name', { required: true })}
                  />
                </Grid>

                <Grid item container spacing={2} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid item xs={12}>
                      <CustomDatePicker
                        sx={{ width: '100%' }}
                        label="Tournament date *"
                        control={control}
                        name="startTime"
                        value={getUtcTime(watch('startTime'))}
                        readOnly={watch('readOnly')}
                        ignoreValidation
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomTimePicker
                        sx={{ width: '100%' }}
                        label="Start Time *"
                        control={control}
                        name="startTime"
                        value={getUtcTime(watch('startTime'))}
                        readOnly={watch('readOnly')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CustomTimePicker
                        sx={{ width: '100%' }}
                        label="End time *"
                        control={control}
                        name="endTime"
                        value={getUtcTime(watch('endTime'))}
                        readOnly={watch('readOnly')}
                      />
                    </Grid>
                  </LocalizationProvider>
                </Grid>

                <Grid
                  item
                  md={3}
                  sx={{ display: { xs: 'none', md: 'block' } }}
                />
                <Grid item container spacing={2} xs={12} md={6}>
                  <Grid item xs={12}>
                    {watch('organization')?.has_permanent_location ? (
                      <LocationBlock
                        location={watch('location')}
                        themeMode={themeMode}
                        withoutLink
                      />
                    ) : (
                      <LocationSelector
                        themeMode={themeMode}
                        currentLocation={watch('location')}
                        onLocationSelect={(location) => {
                          setValue('locationId', location.id, {
                            shouldValidate: true,
                          })
                          setValue('location', location, {
                            shouldValidate: true,
                          })
                          setValue('hasPermanentLocation', true, {
                            shouldValidate: true,
                          })

                          if (
                            watch('locationId') !== tournamentData?.location?.id
                          ) {
                            setCourts([])
                          }
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  md={3}
                  sx={{ display: { xs: 'none', md: 'block' } }}
                />

                {(organizationData?.location || watch('location')) && (
                  <Grid item xs={12}>
                    <LocationCourtsSelector
                      maxCourts={4}
                      courts={watch('location').courts}
                      initialSelectedCourts={courts}
                      onCourtToggle={(court, isSelected) => {
                        if (isSelected) {
                          setCourts([...courts, court])
                        } else {
                          setCourts(courts.filter((c) => c.id !== court.id))
                        }
                      }}
                    />
                  </Grid>
                )}

                <Grid item container spacing={2} xs={12}>
                  <Grid
                    item
                    xs={6}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <MaxUsersSelector
                      defaultMaxUsers={defaultMaxUsers}
                      register={register}
                      setValue={setValue}
                      maxUsersOptions={maxUsersOptions}
                      disabled={!watch('location') || !(courts.length > 0)}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <PointsToPlaySelector
                      setValue={setValue}
                      disabled={!watch('location') || !(courts.length > 0)}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    sx={{
                      width: '100%',
                      height: '100%',
                      '& .MuiInputBase-root': {
                        height: '100%',
                        alignItems: 'start',
                      },
                    }}
                    error={!!errors.description}
                    label="Description"
                    multiline
                    {...register('description')}
                    inputProps={{
                      readOnly: watch('readOnly'),
                      sx: {
                        maxHeight: '100%',
                      },
                    }}
                    maxRows={10}
                  />
                </Grid>

                {adminOrOrganizationAdmin && (
                  <>
                    <Grid
                      item
                      xs={12}
                      mt={2}
                      sx={{ textAlign: 'center', color: 'grey' }}
                    >
                      <Grid
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Info />
                        <Typography variant="body1">
                          {(() => {
                            if (courts?.length > 0) {
                              if (watch('maxUsers') === '') {
                                return 'Please, enter number of max players'
                              }
                              if (est_time === 0) {
                                return "Can't calculate estimated time for this number of courts and players"
                              }

                              return (
                                <>
                                  {'Estimated time: '}
                                  <span>{estimatedTimeString}</span>
                                </>
                              )
                            }

                            return 'Add at least 1 court to tournament'
                          })()}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      md={4}
                      sx={{ display: { xs: 'none', md: 'block' } }}
                    />
                    <Grid item xs={12} md={4}>
                      <Button
                        color="error"
                        fullWidth
                        onClick={handleOpenDeleteTournamentModal}
                        variant="contained"
                      >
                        Delete tournament
                      </Button>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      sx={{ display: { xs: 'none', md: 'block' } }}
                    />

                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        onClick={handleCancelUpdate}
                        type="submit"
                        variant="outlined"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        type="submit"
                        disabled={
                          !Object.keys(collectChanges()).length ||
                          Object.keys(errors).length > 0 ||
                          parseInt(watch('maxUsers'), 10) < users.length
                        }
                        variant="contained"
                        color="success"
                      >
                        {parseInt(watch('maxUsers'), 10) < users.length
                          ? 'Remove excess players first'
                          : 'Submit'}
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </>
            // TODO: move to separate component
          )}
        </FormControl>
      </FormProvider>

      <EasyRegisterDialog
        callback={handleAddUserToTournament}
        open={easyRegisterOpen}
        onClose={() => setEasyRegisterOpen(false)}
      />

      <EasyRegisterDialog
        callback={handleAddUserToTournamentWaitlist}
        open={easyRegisterWaitlistOpen}
        onClose={() => {
          setEasyRegisterWaitlistOpen(false)
        }}
      />

      <RegisterUserToTournamentDialog
        organizationId={organization_id}
        callback={handleAddUserToTournament}
        exceptionIds={[
          ...users.map((u) => u.id),
          ...waitlist_users.map((u) => u.id),
        ]}
        open={selectUserOpen}
        onClose={() => setSelectUserOpen(false)}
      />
      <RegisterUserToTournamentDialog
        organizationId={organization_id}
        callback={handleAddUserToTournamentWaitlist}
        exceptionIds={[
          ...users.map((u) => u.id),
          ...waitlist_users.map((u) => u.id),
        ]}
        open={selectWaitlistUserOpen}
        onClose={() => setSelectWaitlistUserOpen(false)}
      />
      <Dialog
        open={openStartTournamentModal}
        onClose={handleCloseStartTournamentModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to start the tournament?
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Once started, the following will be locked:
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body2">
                - Players and their number
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">- Courts and their number</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                - The maximum number of points
              </Typography>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseStartTournamentModal}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmStartTournament}
            color="primary"
            variant="contained"
            autoFocus
          >
            Start
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteTournamentDialog
        openDeleteTournamentModal={openDeleteTournamentModal}
        handleCloseDeleteTournamentModal={handleCloseDeleteTournamentModal}
        handleConfirmDeleteTournament={handleConfirmDeleteTournament}
      />
    </MainWrapper>
  )
}
