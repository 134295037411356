import React from 'react'
import { Link } from 'react-router-dom'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  Typography,
} from '@mui/material/'

import styles from './SelectTournamentType.module.scss'

export function SelectTournamentType({
  organizationId,
  openSelectTournamentTypeDialog,
  handleCloseSelectTournamentTypeDialog,
}) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      sx={{
        marginTop: {
          xs: 15,
        },
        '& .MuiDialog-container': {
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: { xs: 'flex-start' },
        },
      }}
      open={openSelectTournamentTypeDialog}
      onClose={handleCloseSelectTournamentTypeDialog}
      aria-labelledby="create-tournament-dialog"
    >
      <DialogTitle>
        Select tournament type
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Single Tournaments</Typography>
            <List>
              <ListItem
                sx={{
                  padding: 0,
                  textAlign: 'left',
                  listStyleType: 'disc',
                  display: 'list-item',
                  marginLeft: '24px',
                  marginBottom: '16px',
                }}
              >
                <Typography variant="body1">
                  <Link
                    to={`/organizations/${organizationId}/new-americano-single`}
                    className={styles.tournamentTypeLink}
                  >
                    Americano Single
                  </Link>
                </Typography>
                <Typography variant="body2" color='grey'>
                  Max: 4 courts, 16 players<br />
                  Format: points<br />
                  The tournament duration is calculable
                </Typography>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Team Tournaments</Typography>
            <List>
              <ListItem
                sx={{
                  padding: 0,
                  textAlign: 'left',
                  listStyleType: 'disc',
                  display: 'list-item',
                  marginLeft: '24px',
                  marginBottom: '16px',
                }}
              >
                <Typography variant="body1">
                  <Link
                    to={`/organizations/${organizationId}/new-americano-team`}
                    className={styles.tournamentTypeLink}
                  >
                    Americano Team
                  </Link>
                </Typography>
                <Typography variant="body2" color='grey'>
                  Max: 6 courts, 12 teams<br />
                  Format: points<br />
                  The tournament duration is calculable
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  padding: 0,
                  textAlign: 'left',
                  listStyleType: 'disc',
                  display: 'list-item',
                  marginLeft: '24px',
                  marginBottom: '16px',
                }}
              >
                <Typography variant="body1">
                  <Link
                    to={`/organizations/${organizationId}/new-round-robin-team`}
                    className={styles.tournamentTypeLink}
                  >
                    Round Robin Team
                  </Link>
                </Typography>
                <Typography variant="body2" color='grey'>
                  Max: 6 courts, 12 teams<br />
                  Format: games
                </Typography>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseSelectTournamentTypeDialog}
          color="primary"
          variant="contained"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
