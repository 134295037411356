import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useForm, FormProvider, useWatch } from 'react-hook-form'

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import {
  useCanOrganizatorUpdateUser,
  useUpdateOrganizationMember,
} from 'hooks/api/organizationHooks'
import { useReplaceUserInTournament } from 'hooks/api/tournamentHooks'
import { useUserById } from 'hooks/api/userHooks'

import { UsernameField } from 'components/Fields/Username'
import { GenderField } from 'components/Fields/Gender'
import { UserSearch } from 'components/UserSearch/UserSearch'
import {
  CustomAccordion,
  CustomAccordionDetails,
  CustomAccordionSummary,
} from 'components/CustomAccordion/CustomAccordion'
import { UserAvatarWithName } from 'components/Avatar/UserAvatar'
import { AvailableAvatar } from 'components/Avatar/AvailableAvatar'

import { getUtcTime } from 'utils'

import styles from 'styles/main.module.scss'

/* eslint-disable react/jsx-props-no-spreading */
export function TournamentRegisteredSinglePlayers({
  readOnly = false,
  onlySwapActions = false,
  playerCanInteract = true,
  organizationId,
  tournamentId,
  players,
  waitlistPlayers,
  maxUsers,
  isAdmin,
  currentUser,
  exceptionIds,
  setSelectUserOpen,
  isUserRegistered,
  handleAddUserToTournament,
  handleRemoveUserFromTournament,
  themeMode,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const [selectedPlayer, setSelectedPlayer] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  const handleDialogOpen = (player) => {
    setSelectedPlayer(player)
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
    setSelectedPlayer(null)
  }

  const items = maxUsers
    ? Array.from({ length: maxUsers }, (v, i) => players[i])
    : Array.from({ length: players.length + 1 }, (v, i) => players[i])

  return (
    <>
      <TableContainer component={Paper} className={themeClass}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '50px' }}>№</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Join Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((player, index) =>
              player ? (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <Grid container alignItems="center">
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: isMobile
                            ? 'space-between'
                            : 'flex-start',
                          width: '100%',
                        }}
                      >
                        <Link
                          className={styles.reverseLink}
                          to={`/users/${player.id}`}
                          style={{
                            marginRight: '4px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <UserAvatarWithName
                            key={player.id}
                            user={player}
                            bold={currentUser?.id === player.id}
                          />
                        </Link>
                        {!readOnly && isAdmin && (
                          <IconButton
                            size="small"
                            onClick={() => handleDialogOpen(player)}
                            style={{ marginLeft: '4px' }}
                          >
                            <MoreVertIcon fontSize="small" color="primary" />
                          </IconButton>
                        )}
                        {!readOnly &&
                          !isAdmin &&
                          playerCanInteract &&
                          player.id === currentUser?.id && (
                            <CloseIcon
                              fontSize="small"
                              color="error"
                              style={{ marginLeft: '4px' }}
                              onClick={() =>
                                handleRemoveUserFromTournament(player)
                              }
                            />
                          )}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid style={{ display: 'flex', alignItems: 'center' }}>
                      {player.an_admin_added_the_player && (
                        <SupervisorAccountIcon
                          color="success"
                          fontSize="small"
                          style={{ marginRight: '4px' }}
                        />
                      )}
                      {getUtcTime(player.join_time).format('DD.MM hh:mm a')}
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {!readOnly && !isAdmin && (
                    <TableCell
                      onClick={() =>
                        isUserRegistered ? null : handleAddUserToTournament()
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      <AvailableAvatar disableSecondLableLine />
                    </TableCell>
                  )}
                  {!readOnly && isAdmin && (
                    <TableCell
                      onClick={() => setSelectUserOpen(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      <AvailableAvatar disableSecondLableLine />
                    </TableCell>
                  )}

                  <TableCell />
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <AdminPlayerActionsDialog
        onlySwapActions={onlySwapActions}
        disableGroupColomn
        open={dialogOpen}
        onClose={handleDialogClose}
        player={selectedPlayer}
        players={players}
        waitlistPlayers={waitlistPlayers}
        tournamentId={tournamentId}
        organizationId={organizationId}
        exceptionIds={exceptionIds}
        handleRemoveUserFromTournament={handleRemoveUserFromTournament}
        themeMode={themeMode}
      />

      {players.some((player) => player.an_admin_added_the_player) && (
        <Grid m={1} item xs={12}>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <SupervisorAccountIcon
              color="success"
              fontSize="small"
              style={{ marginRight: '4px' }}
            />
            - Admin added the player
          </Grid>
        </Grid>
      )}
    </>
  )
}

export function TournamentRegisteredSingleWaitlistPlayers({
  readOnly = false,
  tournamentId,
  waitlistPlayers,
  isAdmin,
  currentUser,
  organizationId,
  exceptionIds,
  setSelectWaitlistUserOpen,
  isUserRegistered,
  handleAddUserToTournamentWaitlist,
  handleRemoveUserFromTournamentWaitlist,
  themeMode,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const [selectedPlayer, setSelectedPlayer] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  const handleDialogOpen = (player) => {
    setSelectedPlayer(player)
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
    setSelectedPlayer(null)
  }

  const items = Array.from(
    { length: readOnly ? waitlistPlayers.length : waitlistPlayers.length + 1 },
    (_, i) => waitlistPlayers[i]
  )

  return (
    <>
      <TableContainer component={Paper} className={themeClass}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '50px' }}>№</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Join Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((player, index) =>
              player ? (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <Grid container alignItems="center">
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: isMobile
                            ? 'space-between'
                            : 'flex-start',
                          width: '100%',
                        }}
                      >
                        <Link
                          className={styles.reverseLink}
                          to={`/users/${player.id}`}
                          style={{
                            marginRight: '4px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <UserAvatarWithName
                            key={player.id}
                            user={player}
                            bold={currentUser?.id === player.id}
                          />
                        </Link>
                        {!readOnly && isAdmin && (
                          <IconButton
                            size="small"
                            onClick={() => handleDialogOpen(player)}
                            style={{ marginLeft: '4px' }}
                          >
                            <MoreVertIcon fontSize="small" color="primary" />
                          </IconButton>
                        )}
                        {!readOnly &&
                          !isAdmin &&
                          player.id === currentUser?.id && (
                            <CloseIcon
                              fontSize="small"
                              color="error"
                              style={{ marginLeft: '4px' }}
                              onClick={() =>
                                handleRemoveUserFromTournamentWaitlist(player)
                              }
                            />
                          )}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid style={{ display: 'flex', alignItems: 'center' }}>
                      {player.an_admin_added_the_player && (
                        <SupervisorAccountIcon
                          color="success"
                          fontSize="small"
                          style={{ marginRight: '4px' }}
                        />
                      )}
                      {getUtcTime(player.join_time).format('DD.MM hh:mm a')}
                    </Grid>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {!readOnly && !isAdmin && (
                    <TableCell
                      onClick={() =>
                        isUserRegistered
                          ? null
                          : handleAddUserToTournamentWaitlist()
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      <AvailableAvatar disableSecondLableLine />
                    </TableCell>
                  )}
                  {!readOnly && isAdmin && (
                    <TableCell
                      onClick={() => setSelectWaitlistUserOpen(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      <AvailableAvatar disableSecondLableLine />
                    </TableCell>
                  )}

                  <TableCell />
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <AdminPlayerActionsDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        player={selectedPlayer}
        waitlistPlayers={waitlistPlayers}
        tournamentId={tournamentId}
        organizationId={organizationId}
        exceptionIds={exceptionIds}
        handleRemoveUserFromTournament={handleRemoveUserFromTournamentWaitlist}
        disableGroupColomn
        themeMode={themeMode}
      />

      {waitlistPlayers.some((player) => player.an_admin_added_the_player) && (
        <Grid m={1} item xs={12}>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <SupervisorAccountIcon
              color="success"
              fontSize="small"
              style={{ marginRight: '4px' }}
            />
            - Admin added the player
          </Grid>
        </Grid>
      )}
    </>
  )
}

export function TournamentRegisteredLeftPlayers({ leftPlayers, themeMode }) {
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  const items = Array.from(
    { length: leftPlayers.length },
    (_, i) => leftPlayers[i]
  )

  return (
    <TableContainer component={Paper} className={themeClass}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '50px' }}>№</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Exit Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((player, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{index + 1}</TableCell>
              <TableCell>
                <Grid style={{ display: 'flex', alignItems: 'center' }}>
                  <Link
                    className={styles.link}
                    to={`/users/${player.id}`}
                    style={{ marginRight: '4px' }}
                  >
                    <UserAvatarWithName key={player.id} user={player} />
                  </Link>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid style={{ display: 'flex', alignItems: 'center' }}>
                  {getUtcTime(player.left_time).format('DD.MM hh:mm a')}
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export function TournamentRegisteredTeams({
  readOnly = false,
  onlySwapActions = false,
  playerCanInteract = true,
  organizationId,
  tournamentId,
  teams,
  waitlistTeams,
  maxTeams,
  isAdmin,
  currentUser,
  exceptionIds,
  setSelectUserOpen,
  updateUserGroupId,
  isUserRegistered,
  handleAddUserToTournament,
  handleRemoveUserFromTournament,
  themeMode,
}) {
  const [selectedPlayer, setSelectedPlayer] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  const handleDialogOpen = (player) => {
    setSelectedPlayer(player)
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
    setSelectedPlayer(null)
  }

  const filteredTeams = teams.filter((team) => team.users.length > 0)
  const items = maxTeams
    ? Array.from({ length: maxTeams }, (_, i) => filteredTeams[i])
    : filteredTeams

  return (
    <>
      <TableContainer component={Paper} className={themeClass}>
        <Table aria-label="teams table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '50px' }}>№</TableCell>
              <TableCell>Players</TableCell>
              <TableCell>Join Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((team, teamIndex) => (
              <TableRow
                key={teamIndex}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell key={`teamIndex-${teamIndex}`}>
                  {teamIndex + 1}
                </TableCell>
                <TableCell key={`team-${teamIndex}`}>
                  <Grid
                    container
                    alignItems="top"
                    spacing={1}
                    style={{ flexWrap: 'nowrap' }}
                  >
                    {[0, 1].map((playerIndex) => (
                      <Grid
                        item
                        key={playerIndex}
                        style={{ textAlign: 'center' }}
                      >
                        {team?.users[playerIndex] ? (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <Link
                              className={styles.reverseLink}
                              to={`/users/${team.users[playerIndex].id}`}
                              style={{
                                marginBottom: '4px',
                              }}
                            >
                              <UserAvatarWithName
                                user={team.users[playerIndex]}
                                bold={
                                  team.users[playerIndex].id === currentUser?.id
                                }
                              />
                            </Link>
                            {!readOnly && isAdmin && (
                              <MoreHorizIcon
                                fontSize="small"
                                color="primary"
                                onClick={() =>
                                  handleDialogOpen(team.users[playerIndex])
                                }
                                style={{
                                  cursor: 'pointer',
                                  marginTop: '4px',
                                }}
                              />
                            )}
                            {!readOnly &&
                              !isAdmin &&
                              playerCanInteract &&
                              team.users[playerIndex].id ===
                                currentUser?.id && (
                                <CloseIcon
                                  fontSize="small"
                                  color="error"
                                  style={{
                                    cursor: 'pointer',
                                    marginTop: '4px',
                                  }}
                                  onClick={() =>
                                    handleRemoveUserFromTournament(
                                      team.users[playerIndex]
                                    )
                                  }
                                />
                              )}
                          </div>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            {!readOnly && isAdmin && (
                              <Grid
                                onClick={() => {
                                  updateUserGroupId(
                                    playerIndex ? team?.users[0].group_id : 0
                                  )
                                  setSelectUserOpen(true)
                                }}
                              >
                                <AvailableAvatar
                                  disableSecondLableLine
                                  style={{
                                    cursor: 'pointer',
                                    marginBottom: '4px',
                                  }}
                                />
                              </Grid>
                            )}
                            {!readOnly && !isAdmin && (
                              <Grid
                                onClick={() => {
                                  if (isUserRegistered) return
                                  updateUserGroupId(
                                    playerIndex ? team?.users[0].group_id : 0
                                  )
                                  handleAddUserToTournament()
                                }}
                              >
                                <AvailableAvatar
                                  disableSecondLableLine
                                  style={{
                                    cursor: 'pointer',
                                    marginBottom: '4px',
                                  }}
                                />
                              </Grid>
                            )}
                          </div>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
                <TableCell key={teamIndex}>
                  <Grid
                    container
                    display="flex"
                    direction="column"
                    alignItems="left"
                    justifyContent="center"
                    style={{
                      minHeight: '30px',
                      textAlign: 'center',
                    }}
                  >
                    {[0, 1].map((playerIndex) => (
                      <Grid
                        key={playerIndex}
                        item
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: playerIndex === 0 ? '8px' : '0',
                        }}
                      >
                        {team?.users[playerIndex] ? (
                          <>
                            {team?.users[playerIndex]
                              ?.an_admin_added_the_player && (
                              <SupervisorAccountIcon
                                color="success"
                                fontSize="small"
                                style={{ marginRight: '4px' }}
                              />
                            )}
                            {getUtcTime(
                              team.users[playerIndex].join_time
                            ).format('DD.MM hh:mm a')}
                          </>
                        ) : null}
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AdminPlayerActionsDialog
        onlySwapActions={onlySwapActions}
        open={dialogOpen}
        onClose={handleDialogClose}
        player={selectedPlayer}
        teams={teams}
        waitlistTeams={waitlistTeams}
        tournamentId={tournamentId}
        organizationId={organizationId}
        exceptionIds={exceptionIds}
        handleRemoveUserFromTournament={handleRemoveUserFromTournament}
        themeMode={themeMode}
      />

      {teams.some((team) =>
        team.users.some((user) => user.an_admin_added_the_player)
      ) && (
        <Grid m={1} item xs={12}>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <SupervisorAccountIcon
              color="success"
              fontSize="small"
              style={{ marginRight: '4px' }}
            />
            - Admin added the player
          </Grid>
        </Grid>
      )}
    </>
  )
}

export function TournamentRegisteredWaitlistTeams({
  readOnly = false,
  waitlistTeams,
  isUserRegistered,
  currentUser,
  exceptionIds,
  isAdmin,
  setSelectWaitlistUserOpen,
  updateUserGroupId,
  handleAddUserToTournamentWaitlist,
  handleRemoveUserFromTournamentWaitlist,
  themeMode,
}) {
  const [selectedPlayer, setSelectedPlayer] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  const handleDialogOpen = (player) => {
    setSelectedPlayer(player)
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
    setSelectedPlayer(null)
  }

  const filteredWaitlistTeams = waitlistTeams.filter(
    (team) => team.users.length > 0
  )
  const items = Array.from(
    {
      length: readOnly
        ? filteredWaitlistTeams.length
        : filteredWaitlistTeams.length + 1,
    },
    (_, i) => filteredWaitlistTeams[i]
  )

  return (
    <>
      <TableContainer component={Paper} className={themeClass}>
        <Table aria-label="teams table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '50px' }}>№</TableCell>
              <TableCell>Players</TableCell>
              <TableCell>Join Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((team, teamIndex) => (
              <TableRow
                key={teamIndex}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell key={`teamIndex-${teamIndex}`}>
                  {teamIndex + 1}
                </TableCell>
                <TableCell key={`teamPlayers-${teamIndex}`}>
                  <Grid
                    container
                    alignItems="top"
                    spacing={1}
                    style={{ flexWrap: 'nowrap' }}
                  >
                    {[0, 1].map((playerIndex) => (
                      <Grid
                        item
                        key={playerIndex}
                        style={{ textAlign: 'center' }}
                      >
                        {team?.users[playerIndex] ? (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <Link
                              className={styles.reverseLink}
                              to={`/users/${team.users[playerIndex].id}`}
                              style={{
                                marginBottom: '4px',
                              }}
                            >
                              <UserAvatarWithName
                                user={team.users[playerIndex]}
                                bold={
                                  team.users[playerIndex].id === currentUser?.id
                                }
                              />
                            </Link>
                            {!readOnly && isAdmin && (
                              <MoreHorizIcon
                                fontSize="small"
                                color="primary"
                                onClick={() =>
                                  handleDialogOpen(team.users[playerIndex])
                                }
                                style={{
                                  cursor: 'pointer',
                                  marginTop: '4px',
                                }}
                              />
                            )}
                            {!readOnly &&
                              !isAdmin &&
                              team.users[playerIndex].id ===
                                currentUser?.id && (
                                <CloseIcon
                                  fontSize="small"
                                  color="error"
                                  style={{
                                    cursor: 'pointer',
                                    marginTop: '4px',
                                  }}
                                  onClick={() =>
                                    handleRemoveUserFromTournamentWaitlist(
                                      team.users[playerIndex]
                                    )
                                  }
                                />
                              )}
                          </div>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            {!readOnly && isAdmin && (
                              <Grid
                                onClick={() => {
                                  updateUserGroupId(
                                    playerIndex ? team?.users[0].group_id : 0
                                  )
                                  setSelectWaitlistUserOpen(true)
                                }}
                              >
                                <AvailableAvatar
                                  disableSecondLableLine
                                  style={{
                                    cursor: 'pointer',
                                    marginBottom: '4px',
                                  }}
                                />
                              </Grid>
                            )}
                            {!readOnly && !isAdmin && (
                              <Grid
                                onClick={() => {
                                  if (isUserRegistered) return
                                  updateUserGroupId(
                                    playerIndex ? team?.users[0].group_id : 0
                                  )
                                  handleAddUserToTournamentWaitlist()
                                }}
                              >
                                <AvailableAvatar
                                  disableSecondLableLine
                                  style={{
                                    cursor: 'pointer',
                                    marginBottom: '4px',
                                  }}
                                />
                              </Grid>
                            )}
                          </div>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
                <TableCell key={teamIndex}>
                  <Grid
                    container
                    display="flex"
                    direction="column"
                    alignItems="left"
                    justifyContent="center"
                    style={{
                      minHeight: '30px',
                      textAlign: 'center',
                    }}
                  >
                    {[0, 1].map((playerIndex) => (
                      <Grid
                        key={playerIndex}
                        item
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: playerIndex === 0 ? '8px' : '0',
                        }}
                      >
                        {team?.users[playerIndex] ? (
                          <>
                            {team?.users[playerIndex]
                              ?.an_admin_added_the_player && (
                              <SupervisorAccountIcon
                                color="success"
                                fontSize="small"
                                style={{ marginRight: '4px' }}
                              />
                            )}
                            {getUtcTime(
                              team.users[playerIndex].join_time
                            ).format('DD.MM hh:mm a')}
                          </>
                        ) : null}
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AdminPlayerActionsDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        player={selectedPlayer}
        waitlistTeams={waitlistTeams}
        exceptionIds={exceptionIds}
        handleRemoveUserFromTournament={handleRemoveUserFromTournamentWaitlist}
        themeMode={themeMode}
      />

      {waitlistTeams.some((team) =>
        team.users.some((user) => user.an_admin_added_the_player)
      ) && (
        <Grid m={1} item xs={12}>
          <Grid style={{ display: 'flex', alignItems: 'center' }}>
            <SupervisorAccountIcon
              color="success"
              fontSize="small"
              style={{ marginRight: '4px' }}
            />
            - Admin added the player
          </Grid>
        </Grid>
      )}
    </>
  )
}

export function AdminPlayerActionsDialog({
  open,
  onClose,
  player,
  players = null,
  waitlistPlayers = null,
  teams = null,
  waitlistTeams = null,
  organizationId,
  tournamentId,
  exceptionIds,
  handleRemoveUserFromTournament,
  disableGroupColomn,
  onlySwapActions = false,
  themeMode,
}) {
  const [expanded, setExpanded] = useState()
  const [searchString, setSearchString] = useState('')
  const [onlyOrganizationMembers, setOnlyOrganizationMembers] = useState(true)
  const {
    trigger: canUpdateTrigger,
    data: canUpdateData,
    isLoading,
  } = useCanOrganizatorUpdateUser({ userId: player?.id })
  const { trigger: replaceUserTrigger } = useReplaceUserInTournament()

  useEffect(() => {
    if (open && player) {
      canUpdateTrigger()
    }
  }, [open, player, canUpdateTrigger])

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handleReplaceUser = (newUserId) => {
    replaceUserTrigger(
      {
        tournament_id: tournamentId,
        old_user_id: player.id,
        new_user_id: newUserId,
      },
      {
        onSuccess: () => {
          onClose()
          window.location.reload()
        },
        onError: (e) => {
          console.error(e)
        },
      }
    )
  }

  const notMePlayers =
    player && players ? players.filter((p) => p.id !== player.id) : players

  const notMeWaitlistPlayers =
    player && waitlistPlayers
      ? waitlistPlayers.filter((p) => p.id !== player.id)
      : waitlistPlayers

  const notMyTeams =
    player && teams
      ? teams.filter(
          (team) => !team.users.some((user) => user.id === player.id)
        )
      : teams

  const notMyWaitlistTeams =
    player && waitlistTeams
      ? waitlistTeams.filter(
          (team) => !team.users.some((user) => user.id === player.id)
        )
      : waitlistTeams

  const renderContent = () => {
    if (isLoading) {
      return <CircularProgress />
    }

    return (
      <>
        {(players || teams) && (
          <CustomAccordion
            expanded={expanded === 'panel1'}
            onChange={handleAccordionChange('panel1')}
          >
            <CustomAccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>Replace In-Tournament</Typography>
            </CustomAccordionSummary>
            <CustomAccordionDetails>
              <CurrentParticipantsList
                players={notMePlayers}
                teams={notMyTeams}
                callback={(user) => handleReplaceUser(user.id)}
                themeMode={themeMode}
                disableGroupColomn={disableGroupColomn}
                open={expanded === 'panel1'}
              />
            </CustomAccordionDetails>
          </CustomAccordion>
        )}

        {(waitlistPlayers || waitlistTeams) && (
          <CustomAccordion
            expanded={expanded === 'panel4'}
            onChange={handleAccordionChange('panel4')}
          >
            <CustomAccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Typography>Replace In-Waitlist</Typography>
            </CustomAccordionSummary>
            <CustomAccordionDetails>
              <WaitlistParticipantsList
                players={notMeWaitlistPlayers}
                teams={notMyWaitlistTeams}
                callback={(user) => handleReplaceUser(user.id)}
                themeMode={themeMode}
                open={expanded === 'panel4'}
              />
            </CustomAccordionDetails>
          </CustomAccordion>
        )}

        <CustomAccordion
          expanded={expanded === 'panel2'}
          onChange={handleAccordionChange('panel2')}
        >
          <CustomAccordionSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography>Replace Out-Tournament</Typography>
          </CustomAccordionSummary>
          <CustomAccordionDetails>
            <UserSearch
              organizationId={organizationId}
              callback={(user) => handleReplaceUser(user.id)}
              exceptionIds={exceptionIds}
              searchString={searchString}
              setSearchString={setSearchString}
              onlyOrganizationMembers={onlyOrganizationMembers}
              setOnlyOrganizationMembers={setOnlyOrganizationMembers}
              themeMode={themeMode}
              open={expanded === 'panel2'}
            />
          </CustomAccordionDetails>
        </CustomAccordion>

        <CustomAccordion
          expanded={expanded === 'panel3'}
          onChange={handleAccordionChange('panel3')}
        >
          <CustomAccordionSummary
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography>Edit</Typography>
          </CustomAccordionSummary>
          <CustomAccordionDetails>
            {canUpdateData?.can_update ? (
              <EditPlayerForm player={player} onClose={onClose} />
            ) : (
              <Typography>
                You do not have permission to edit this player.
              </Typography>
            )}
          </CustomAccordionDetails>
        </CustomAccordion>
      </>
    )
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        What do you want to do with {player?.name} {player?.surname}?
      </DialogTitle>
      <DialogContent sx={{ paddingLeft: '0px', paddingRight: '0px' }}>
        {renderContent()}
      </DialogContent>
      <DialogActions>
        {!onlySwapActions && (
          <Button
            onClick={() => {
              handleRemoveUserFromTournament(player)
              onClose()
            }}
            color="error"
            variant="contained"
          >
            Remove Player
          </Button>
        )}
        <Button
          onClick={() => {
            onClose()
            setExpanded(null)
          }}
          color="primary"
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function CurrentParticipantsList({
  players,
  teams,
  callback,
  disableGroupColomn = false,
  themeMode,
}) {
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TableContainer component={Paper} className={themeClass}>
          <Table>
            <TableHead>
              <TableRow>
                {!disableGroupColomn && (
                  <TableCell style={{ width: '50px' }}>Group</TableCell>
                )}
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {players &&
                players.length > 0 &&
                players.map((p) => (
                  <TableRow
                    key={p.id}
                    onClick={() => {
                      callback(p, true)
                    }}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                      },
                    }}
                  >
                    {!disableGroupColomn && (
                      <TableCell>
                        <Grid style={{ marginLeft: '15px' }}>{p.group_id}</Grid>
                      </TableCell>
                    )}
                    <TableCell>
                      {p.name} {p.surname}
                    </TableCell>
                  </TableRow>
                ))}

              {teams &&
                teams.map((team, teamIndex) => (
                  <TableRow
                    key={teamIndex}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {!disableGroupColomn && team?.users[0]?.group_id && (
                      <TableCell key={`teamGroup-${teamIndex}`}>
                        <Grid style={{ marginLeft: '15px' }}>
                          {team?.users[0]?.group_id}
                        </Grid>
                      </TableCell>
                    )}
                    <TableCell key={`team-${teamIndex}`}>
                      {team.users.map((user, userIndex) => (
                        <Grid
                          key={userIndex}
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            marginBottom:
                              userIndex < team.users.length - 1
                                ? '20px'
                                : 'none',
                            cursor: 'pointer',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            },
                          }}
                          onClick={() => {
                            callback(user, true)
                          }}
                        >
                          {user ? (
                            <Grid
                              item
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              {user.name} {user.surname}
                            </Grid>
                          ) : (
                            <Grid item style={{ width: '100%' }} />
                          )}
                        </Grid>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              {!players?.length && !teams?.length && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No participants found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export function WaitlistParticipantsList({
  players = null,
  teams = null,
  callback,
  disableGroupColomn = false,
  themeMode,
}) {
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TableContainer component={Paper} className={themeClass}>
          <Table>
            <TableHead>
              <TableRow>
                {!disableGroupColomn && (
                  <TableCell style={{ width: '50px' }}>Group</TableCell>
                )}
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {players &&
                players.length > 0 &&
                players.map((p) => (
                  <TableRow
                    key={p.id}
                    onClick={() => {
                      callback(p, true)
                    }}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.3)',
                      },
                    }}
                  >
                    {!disableGroupColomn && (
                      <TableCell>
                        <Grid style={{ marginLeft: '15px' }}>{p.group_id}</Grid>
                      </TableCell>
                    )}
                    <TableCell>
                      {p.name} {p.surname}
                    </TableCell>
                  </TableRow>
                ))}

              {teams &&
                teams.map((team, teamIndex) => (
                  <TableRow
                    key={teamIndex}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {!disableGroupColomn && team?.users[0]?.group_id && (
                      <TableCell key={`teamGroup-${teamIndex}`}>
                        <Grid style={{ marginLeft: '15px' }}>
                          {team?.users[0]?.group_id}
                        </Grid>
                      </TableCell>
                    )}
                    <TableCell key={`team-${teamIndex}`}>
                      {team.users.map((user, userIndex) => (
                        <Grid
                          key={userIndex}
                          container
                          alignItems="center"
                          justifyContent="space-between"
                          sx={{
                            marginBottom:
                              userIndex < team.users.length - 1
                                ? '20px'
                                : 'none',
                            cursor: 'pointer',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            },
                          }}
                          onClick={() => {
                            callback(user, true)
                          }}
                        >
                          {user ? (
                            <Grid
                              item
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              {user.name} {user.surname}
                            </Grid>
                          ) : (
                            <Grid item style={{ width: '100%' }} />
                          )}
                        </Grid>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              {!players?.length && !teams?.length && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No participants found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

function EditPlayerForm({ player, onClose }) {
  const { data: userData } = useUserById(player?.id || '0')
  const { trigger: updateUserTrigger } = useUpdateOrganizationMember()

  const methods = useForm({
    defaultValues: {
      name: userData?.name || '',
      surname: userData?.surname || '',
      username: userData?.username || '',
      email: userData?.email || '',
      phone: userData?.phone || '',
    },
  })

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
  } = methods

  useEffect(() => {
    if (userData) {
      reset({
        name: userData.name,
        surname: userData.surname,
        username: userData.username,
        email: userData.email,
        phone: userData.phone,
      })
    }
  }, [userData, reset])

  const handleReset = () => {
    reset({
      name: userData.name,
      surname: userData.surname,
      username: userData.username,
      email: userData.email,
      phone: userData.phone,
    })
  }

  const handleEditPlayerSubmit = (data) => {
    updateUserTrigger(
      {
        id: player.id,
        ...data,
      },
      {
        onSuccess: () => {
          onClose()
          window.location.reload()
        },
        onError: (e) => {
          console.error(e)
        },
      }
    )
  }

  const watchFields = useWatch({ control })
  const hasChanges = Object.keys(watchFields).some(
    (key) => watchFields[key] !== (userData ? userData[key] || '' : '')
  )

  return (
    <FormProvider {...methods}>
      <FormControl
        component="form"
        onSubmit={handleSubmit(handleEditPlayerSubmit)}
        fullWidth
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Name"
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ''}
              {...register('name', { required: 'Name is required' })}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Surname"
              {...register('surname')}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <UsernameField initialUsername={userData?.username} />
          </Grid>
          <Grid item xs={12}>
            <GenderField initialGender={userData?.gender} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              {...register('email')}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Phone"
              {...register('phone')}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            onClick={handleReset}
            color="primary"
            variant="contained"
            disabled={!hasChanges}
          >
            Reset
          </Button>
          <Button type="submit" color="success" variant="contained">
            Save
          </Button>
        </DialogActions>
      </FormControl>
    </FormProvider>
  )
}
