import React, { useMemo, useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineUser, AiOutlineTeam } from 'react-icons/ai'
import { Box, Grid, Paper, Typography, Divider } from '@mui/material'

import { UserAvatarWithName } from 'components/Avatar/UserAvatar'
import { AvailableAvatar } from 'components/Avatar/AvailableAvatar'
import { RemainingPlayersAvatar } from 'components/Avatar/RemainingPlayersAvatar'

import { capitalizeWords, getUserPlaceInTournament, getUtcTime } from 'utils'

import styles from './TournamentBlock.module.scss'

export function AvatarComponent({ users, maxAvatars, showAvailable }) {
  let showUsers = users
  const totalUsers = users.length
  const remainingPlayersCount =
    totalUsers - maxAvatars + (showAvailable ? 2 : 1)
  const showRemainingPlayers = remainingPlayersCount > 0

  if (showAvailable && totalUsers >= maxAvatars) {
    showUsers = users.slice(0, maxAvatars - 1)
  } else if (totalUsers > maxAvatars) {
    showUsers = users.slice(0, maxAvatars)
  }

  if (showRemainingPlayers) {
    showUsers = showUsers.slice(0, showUsers.length - 1)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'top',
        mt: 1,
      }}
    >
      {showAvailable && <AvailableAvatar />}
      {showUsers.map((user) => (
        <UserAvatarWithName key={user.id} user={user} />
      ))}
      {showRemainingPlayers && (
        <RemainingPlayersAvatar count={remainingPlayersCount} />
      )}
    </Box>
  )
}

export function TournamentDetails({ tournament }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Typography
        variant="subtitle1"
        className={styles.organizationName}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: tournament.status === 'created' ? '60%' : '70%',
        }}
      >
        {tournament.organization_name}
      </Typography>
      {tournament.status === 'created' ? (
        <Grid className={styles.userCount}>
          <Typography variant="body2">
            {tournament.type.includes('team')
              ? tournament.users.length / 2
              : tournament.users.length}{' '}
            /{' '}
            {tournament.type.includes('team')
              ? tournament.max_users / 2
              : tournament.max_users}
          </Typography>
          {tournament.type.includes('team') ? (
            <AiOutlineTeam style={{ fontSize: '1rem' }} />
          ) : (
            <AiOutlineUser style={{ fontSize: '1rem' }} />
          )}
        </Grid>
      ) : (
        <Grid className={styles.userCount}>
          <Typography variant="body2">
            {tournament.type.includes('team')
              ? tournament.users.length / 2
              : tournament.users.length}
          </Typography>
          {tournament.type.includes('team') ? (
            <AiOutlineTeam style={{ fontSize: '1rem' }} />
          ) : (
            <AiOutlineUser style={{ fontSize: '1rem' }} />
          )}
        </Grid>
      )}
    </Box>
  )
}

export function TournamentBlock({
  showAvailable = false,
  tournament,
  startTimeFormat = 'DD.MM.YYYY',
  themeMode,
}) {
  const themeClass = useMemo(
    () => (themeMode === 'dark' ? styles.darkTheme : styles.lightTheme),
    [themeMode]
  )

  const blockRef = useRef(null)
  const [maxAvatars, setMaxAvatars] = useState(6)
  const tournamentIsFull = tournament.users.length >= tournament.max_users

  useEffect(() => {
    const calculateMaxAvatars = () => {
      if (blockRef.current) {
        const blockWidth = blockRef.current.offsetWidth
        const avatarWidth = 60 // width + text
        const availableSpace = blockWidth - 16 // padding
        const maxPossibleAvatars = Math.floor(availableSpace / avatarWidth)
        setMaxAvatars(Math.max(1, maxPossibleAvatars))
      }
    }

    calculateMaxAvatars()
    window.addEventListener('resize', calculateMaxAvatars)
    return () => window.removeEventListener('resize', calculateMaxAvatars)
  }, [])

  return (
    <Link
      to={`/tournament/${tournament.id}`}
      className={styles.tournamentBlockLink}
    >
      <Paper
        ref={blockRef}
        className={`${styles.tournamentBlock} ${themeClass}`}
        sx={{
          padding: 2,
          boxShadow: 3,
          borderRadius: 2,
          transition: 'transform 0.3s, box-shadow 0.3s',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography variant="caption" color="grey" sx={{ marginBottom: 1 }}>
            {getUtcTime(tournament.start_time).format(startTimeFormat)}
          </Typography>
          <Typography
            variant="h6"
            className={styles.tournamentName}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%',
            }}
          >
            {tournament.name}
          </Typography>
          <Divider sx={{ width: '100%' }} />
        </Box>
        <Grid className={styles.tournamentDetails} sx={{ marginBottom: 2 }}>
          <TournamentDetails tournament={tournament} />
        </Grid>
        <AvatarComponent
          users={tournament.users}
          maxAvatars={maxAvatars}
          showAvailable={showAvailable && !tournamentIsFull}
        />
        <Divider sx={{ width: '100%', marginTop: 1, marginBottom: 2 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 2,
          }}
        >
          <Typography variant="body2" className={styles.tournamentType}>
            {capitalizeWords(tournament.type.replaceAll('_', ' '))}
          </Typography>
          {tournament.price && (
            <Typography variant="body2" className={styles.tournamentPrice}>
              {tournament.price}
            </Typography>
          )}
        </Box>
      </Paper>
    </Link>
  )
}

export function UserFinishedTournamentBlock({
  tournament,
  user_id,
  startTimeFormat = 'DD.MM.YYYY',
  themeMode,
}) {
  const themeClass = useMemo(
    () => (themeMode === 'dark' ? styles.darkTheme : styles.lightTheme),
    [themeMode]
  )

  return (
    <Link
      to={`/tournament/${tournament.id}`}
      className={styles.tournamentBlockLink}
    >
      <Paper
        className={`${styles.tournamentBlock} ${themeClass}`}
        sx={{
          padding: 2,
          boxShadow: 3,
          borderRadius: 2,
          transition: 'transform 0.3s, box-shadow 0.3s',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <Typography variant="caption" color="grey" sx={{ marginBottom: 1 }}>
            {getUtcTime(tournament.start_time).format(startTimeFormat)}
          </Typography>
          <Typography
            variant="h6"
            className={styles.tournamentName}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '100%',
            }}
          >
            {getUserPlaceInTournament(tournament.leaderboard, user_id)}{' '}
            {tournament.name}
          </Typography>
          <Divider sx={{ width: '100%' }} />
        </Box>
        <Grid className={styles.tournamentDetails} sx={{ marginBottom: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Typography
              variant="subtitle1"
              className={styles.organizationName}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '70%',
              }}
            >
              {tournament.organization_name}
            </Typography>
            <Grid className={styles.userCount}>
              <Typography variant="body2">
                {tournament.type.includes('team')
                  ? tournament.users.length / 2
                  : tournament.users.length}
              </Typography>
              {tournament.type.includes('team') ? (
                <AiOutlineTeam style={{ fontSize: '1rem' }} />
              ) : (
                <AiOutlineUser style={{ fontSize: '1rem' }} />
              )}
            </Grid>
          </Box>
        </Grid>
        <AvatarComponent
          users={tournament.users}
          maxAvatars={6}
          showAvailable={false}
        />
        <Divider sx={{ width: '100%', marginTop: 1, marginBottom: 2 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 2,
          }}
        >
          <Typography variant="body2" className={styles.tournamentType}>
            {capitalizeWords(tournament.type.replaceAll('_', ' '))}
          </Typography>
          {tournament.price && (
            <Typography variant="body2" className={styles.tournamentPrice}>
              {tournament.price}
            </Typography>
          )}
        </Box>
      </Paper>
    </Link>
  )
}
