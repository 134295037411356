import React, { useState, useEffect } from 'react'
import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardActionArea,
} from '@mui/material'

import styles from './LocationCourts.module.scss'

export function LocationCourtsSelector({
  courts,
  onCourtToggle,
  maxCourts = 100,
  singleCourtAvailable = false,
  initialSelectedCourts = null,
  themeMode,
}) {
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  const initialSelection = initialSelectedCourts
    ? initialSelectedCourts.reduce((acc, court) => {
        acc[court.id] = true
        return acc
      }, {})
    : {}

  const [selectedCourts, setSelectedCourts] = useState(initialSelection)

  const handleCourtChange = (court) => {
    if (court.size === 'single' && !singleCourtAvailable) return

    const numberOfSelectedCourts =
      Object.values(selectedCourts).filter(Boolean).length

    // Toggle selection only if we haven't reached the maxCourts limit or if unselecting
    if (selectedCourts[court.id] || numberOfSelectedCourts < maxCourts) {
      const isSelected = !selectedCourts[court.id]
      const updatedSelection = {
        ...selectedCourts,
        [court.id]: isSelected,
      }
      setSelectedCourts(updatedSelection)
      onCourtToggle(court, isSelected)
    }
  }

  useEffect(() => {
    if (
      initialSelectedCourts &&
      initialSelectedCourts.every((court) =>
        courts.some((c) => c.id === court.id)
      )
    ) {
      setSelectedCourts(initialSelection)
    }
  }, [courts])

  const courtStatusClass = (court) => {
    if (court.size === 'single' && !singleCourtAvailable) return styles.courtNotAvailable
    if (
      selectedCourts &&
      Object.keys(selectedCourts).filter((key) => selectedCourts[key]).length >=
        maxCourts &&
      !selectedCourts[court.id]
    ) {
      return styles.courtLimitReached
    }
    return styles.courtAvailable
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ textAlign: 'center' }}>
          Select Courts {maxCourts < 100 && `(up to ${maxCourts} courts)`}
        </Typography>
      </Grid>
      {courts.map((court) => (
        <Grid item xs={6} md={4} key={court.id}>
          <Card
            className={`${styles.locationCourtBlock} ${
              selectedCourts && selectedCourts[court.id] ? styles.selected : ''
            } ${courtStatusClass(court)} ${themeClass}`}
            variant="outlined"
            onClick={() => handleCourtChange(court)}
          >
            <CardActionArea
              disabled={
                (Object.keys(selectedCourts).filter(
                  (key) => selectedCourts[key]
                ).length >= maxCourts &&
                  !selectedCourts[court.id])
              }
            >
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {court.size === 'single' && !singleCourtAvailable ? 'Not available' : court.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Type: {court.court_type}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Walls: {court.walls_type}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Size: {court.size}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}
