import React, { useState, useEffect } from 'react'

import { Box, Grid, Pagination, Typography } from '@mui/material'

import { useTournaments } from 'hooks/api/tournamentHooks'
import { useResponsive } from 'hooks/responsive'

import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import { TournamentBlock } from 'components/TournamentBlock/TournamentBlock'
import { PulsingIcon } from 'components/PulsingIcon/PulsingIcon'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'

import { getUtcTime } from 'utils'

export function Tournaments() {
  const { isScreenMdOrMore } = useResponsive()

  const { themeMode } = useThemeSwitcher()

  const limit = isScreenMdOrMore ? 6 : 3
  const [currentPageCreated, setCurrentPageCreated] = useState(1)
  const [totalCreated, setTotalCreated] = useState(0)
  const offsetCreated = (currentPageCreated - 1) * limit
  const countCreated = Math.ceil(totalCreated / limit)

  const [inProgressPageCreated, setInProgressPageCreated] = useState(1)
  const [inProgressTotalCreated, setInProgressTotalCreated] = useState(0)
  const inProgressOffsetCreated = (inProgressPageCreated - 1) * limit
  const inProgressCountCreated = Math.ceil(inProgressTotalCreated / limit)

  const [currentPageFinished, setCurrentPageFinished] = useState(1)
  const [totalFinished, setTotalFinished] = useState(0)
  const offsetFinished = (currentPageFinished - 1) * limit
  const countFinished = Math.ceil(totalFinished / limit)

  const currentDate = getUtcTime().format('DD.MM.YYYY')
  const yesterdayDate = getUtcTime().subtract(1, 'day').format('DD.MM.YYYY')

  const {
    data: createdTournamentsData,
    isLoading: createdTournamentsDataLoading,
  } = useTournaments({
    status: 'created',
    after_date: currentDate,
    limit,
    offset: offsetCreated,
    is_deleted: false,
  })

  const {
    data: inProgressTournamentsData,
    isLoading: inProgressTournamentsDataLoading,
  } = useTournaments({
    status: 'in_progress',
    after_date: yesterdayDate,
    limit,
    offset: inProgressOffsetCreated,
    is_deleted: false,
  })

  const {
    data: finishedTournamentsData,
    isLoading: finishedTournamentsDataLoading,
  } = useTournaments({
    status: 'finished',
    order: 'desc',
    limit,
    offset: offsetFinished,
    is_deleted: false,
  })

  useEffect(() => {
    if (createdTournamentsData?.total) {
      setTotalCreated(createdTournamentsData.total)
    }

    if (inProgressTournamentsData?.total) {
      setInProgressTotalCreated(inProgressTournamentsData.total)
    }

    if (finishedTournamentsData?.total) {
      setTotalFinished(finishedTournamentsData.total)
    }
  }, [
    createdTournamentsData,
    inProgressTournamentsData,
    finishedTournamentsData,
  ])

  const handlePageCreatedChange = (_, page) => {
    setCurrentPageCreated(page)
  }

  const handlePageFinishedChange = (_, page) => {
    setCurrentPageFinished(page)
  }

  if (
    (createdTournamentsDataLoading,
    inProgressTournamentsDataLoading,
    finishedTournamentsDataLoading)
  ) {
    return (
      <MainWrapper>
        <Grid container justifyContent="center" alignItems="center">
          <Box>
            <video
              src="/video.mp4"
              autoPlay
              loop
              muted
              playsInline
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
            />
          </Box>
        </Grid>
      </MainWrapper>
    )
  }

  return (
    <MainWrapper maxWidth="md">
      <Grid mb={4} container spacing={2}>
        {inProgressTournamentsData?.data.length > 0 && (
          <>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography variant="h5">Live tournaments</Typography>
                <PulsingIcon color="error" fontSize="medium" />
              </Box>
            </Grid>
            {inProgressTournamentsData?.data.map((tournament) => (
              <Grid item xs={12} md={4} key={tournament.id}>
                <TournamentBlock
                  tournament={tournament}
                  startTimeFormat="DD.MM.YYYY hh:mm A"
                  themeMode={themeMode}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Pagination
                count={inProgressCountCreated}
                page={inProgressPageCreated}
                shape="rounded"
                onChange={setInProgressPageCreated}
                siblingCount={0}
                size="medium"
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h5">Open tournaments</Typography>
        </Grid>
        {createdTournamentsData?.data.length > 0 ? (
          <>
            {createdTournamentsData?.data.map((tournament) => (
              <Grid item xs={12} md={4} key={tournament.id}>
                <TournamentBlock
                  showAvailable
                  tournament={tournament}
                  startTimeFormat="DD.MM.YYYY hh:mm A"
                  themeMode={themeMode}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Pagination
                count={countCreated}
                page={currentPageCreated}
                shape="rounded"
                onChange={handlePageCreatedChange}
                siblingCount={0}
                size="medium"
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sx={{ textAlign: 'center', color: 'grey' }}>
            <Typography variant="body1">No open tournaments</Typography>
          </Grid>
        )}

        <Grid item mt={2} xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h5">Latest finished tournaments</Typography>
        </Grid>

        {finishedTournamentsData?.data.length > 0 ? (
          <>
            {finishedTournamentsData?.data.map((tournament) => (
              <Grid item xs={12} md={4} key={tournament.id}>
                <TournamentBlock
                  tournament={tournament}
                  startTimeFormat="DD.MM.YYYY hh:mm A"
                  themeMode={themeMode}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Pagination
                count={countFinished}
                page={currentPageFinished}
                shape="rounded"
                onChange={handlePageFinishedChange}
                siblingCount={0}
                size="medium"
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sx={{ textAlign: 'center', color: 'grey' }}>
            <Typography variant="body1">No finished tournaments</Typography>
          </Grid>
        )}
      </Grid>
    </MainWrapper>
  )
}
