import React from 'react'
import { Link } from 'react-router-dom'

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { UserAvatarWithName, UserAvatar } from 'components/Avatar/UserAvatar'

import styles from 'styles/main.module.scss'

export function AmericanoSingleLeaderboardTable({
  leaderboard,
  maxUserNameAndSurnameLength = 20,
  onlyPoints = false,
  viewMode,
  themeMode,
}) {
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  let place = 1

  return (
    <TableContainer component={Paper} className={themeClass}>
      <Table size={viewMode ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              sx={{ paddingTop: '12px', paddingBottom: '12px' }}
            >
              <Typography variant="h6">🥇</Typography>
            </TableCell>
            <TableCell
              align="left"
              sx={{ paddingTop: '12px', paddingBottom: '12px' }}
            >
              <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                Name
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ paddingTop: '12px', paddingBottom: '12px' }}
            >
              <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                Points
              </Typography>
            </TableCell>
            {!onlyPoints && (
              <>
                <TableCell
                  align="center"
                  sx={{ paddingTop: '12px', paddingBottom: '12px' }}
                >
                  <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                    W
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ paddingTop: '12px', paddingBottom: '12px' }}
                >
                  <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                    D
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ paddingTop: '12px', paddingBottom: '12px' }}
                >
                  <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                    L
                  </Typography>
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        {leaderboard && (
          <TableBody>
            {leaderboard.map((player, index) => {
              const previousPlayer = leaderboard[index - 1]
              const currentPlayer = player

              if (
                previousPlayer &&
                previousPlayer.americano_data.points !==
                  currentPlayer.americano_data.points
              ) {
                place = index + 1
              }

              return (
                <TableRow key={player.user_id}>
                  <TableCell align="center">
                    <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                      {place}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {viewMode ? (
                      <Box display="flex" alignItems="center">
                        <UserAvatar
                          user={player}
                          sx={{ backgroundColor: 'white' }}
                        />
                        <Typography
                          ml={1}
                          variant="h6"
                          style={{ fontWeight: 'normal' }}
                        >
                          {`${player.name} ${player.surname}`.substring(
                            0,
                            maxUserNameAndSurnameLength
                          ) +
                            (player.name.length + player.surname.length >
                            maxUserNameAndSurnameLength
                              ? '...'
                              : '')}
                        </Typography>
                      </Box>
                    ) : (
                      <Link
                        className={styles.reverseLink}
                        to={`/users/${player.user_id}`}
                      >
                        <UserAvatarWithName
                          key={player.user_id}
                          user={player}
                        />
                      </Link>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                      {player.americano_data.points}
                    </Typography>
                  </TableCell>
                  {!onlyPoints && (
                    <>
                      <TableCell align="center">
                        <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                          {player.americano_data.wins}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                          {player.americano_data.draws}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                          {player.americano_data.loses}
                        </Typography>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}

export function AmericanoTeamLeaderboardTable({
  leaderboard,
  maxUserNameAndSurnameLength = 20,
  viewMode,
  themeMode,
  onlyPoints = false,
}) {
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  let place = 1

  return (
    <TableContainer component={Paper} className={themeClass}>
      <Table size={viewMode ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              sx={{ paddingTop: '12px', paddingBottom: '12px' }}
            >
              <Typography variant="h6">🥇</Typography>
            </TableCell>
            <TableCell
              align="left"
              sx={{ paddingTop: '12px', paddingBottom: '12px' }}
            >
              <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                Team Members
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ paddingTop: '12px', paddingBottom: '12px' }}
            >
              <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                Points
              </Typography>
            </TableCell>
            {!onlyPoints && (
              <>
                <TableCell
                  align="center"
                  sx={{ paddingTop: '12px', paddingBottom: '12px' }}
                >
                  <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                    W
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ paddingTop: '12px', paddingBottom: '12px' }}
                >
                  <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                    D
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ paddingTop: '12px', paddingBottom: '12px' }}
                >
                  <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                    L
                  </Typography>
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        {leaderboard && (
          <TableBody>
            {leaderboard.map((leaderboardElement, teamIndex) => {
              const previousTeam = leaderboard[teamIndex - 1]?.team[0]
              const currentTeam = leaderboardElement.team[0]

              if (
                previousTeam &&
                previousTeam.americano_data.points !==
                  currentTeam.americano_data.points
              ) {
                place = teamIndex + 1
              }

              return (
                <TableRow key={teamIndex}>
                  <TableCell align="center">
                    <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                      {place}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {viewMode ? (
                      leaderboardElement.team.map((member) => (
                        <Box display="flex" alignItems="center" mt={1}>
                          <UserAvatar
                            user={member}
                            sx={{ backgroundColor: 'white' }}
                          />
                          <Typography
                            ml={1}
                            variant={viewMode ? 'h6' : 'subtitle1'}
                            style={{ fontWeight: 'normal' }}
                          >
                            {`${member.name} ${member.surname}`.substring(
                              0,
                              maxUserNameAndSurnameLength
                            ) +
                              (member.name.length + member.surname.length >
                              maxUserNameAndSurnameLength
                                ? '...'
                                : '')}
                          </Typography>
                        </Box>
                      ))
                    ) : (
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        {leaderboardElement.team.map((member) => (
                          <Link
                            to={`/users/${member.user_id}`}
                            key={member.user_id}
                            className={styles.reverseLink}
                          >
                            <UserAvatarWithName user={member} />
                          </Link>
                        ))}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                      {currentTeam.americano_data.points}
                    </Typography>
                  </TableCell>
                  {!onlyPoints && (
                    <>
                      <TableCell align="center">
                        <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                          {currentTeam.americano_data.wins}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                          {currentTeam.americano_data.draws}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                          {currentTeam.americano_data.loses}
                        </Typography>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}

export function RoundRobinLeaderboardTable({
  leaderboard,
  maxUserNameAndSurnameLength = 20,
  viewMode,
  themeMode,
}) {
  const themeClass = themeMode === 'dark' ? styles.darkTheme : styles.lightTheme

  let place = 1

  return (
    <TableContainer component={Paper} className={themeClass}>
      <Table size={viewMode ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              sx={{ paddingTop: '12px', paddingBottom: '12px' }}
            >
              <Typography variant="h6">🥇</Typography>
            </TableCell>
            <TableCell
              align="left"
              sx={{ paddingTop: '12px', paddingBottom: '12px' }}
            >
              <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                Team Members
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ paddingTop: '12px', paddingBottom: '12px' }}
            >
              <Typography variant={viewMode ? 'h6' : 'subtitle1'}>W</Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ paddingTop: '12px', paddingBottom: '12px' }}
            >
              <Typography variant={viewMode ? 'h6' : 'subtitle1'}>D</Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ paddingTop: '12px', paddingBottom: '12px' }}
            >
              <Typography variant={viewMode ? 'h6' : 'subtitle1'}>L</Typography>
            </TableCell>
            <TableCell
              align="center"
              sx={{ paddingTop: '12px', paddingBottom: '12px' }}
            >
              <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                Diff
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        {leaderboard && (
          <TableBody>
            {leaderboard.map((leaderboardElement, teamIndex) => {
              const previousTeam = leaderboard[teamIndex - 1]?.team[0]
              const currentTeam = leaderboardElement.team[0]

              if (
                previousTeam &&
                (previousTeam.round_robin_data.wins !==
                  currentTeam.round_robin_data.wins ||
                  previousTeam.round_robin_data.games_difference !==
                    currentTeam.round_robin_data.games_difference)
              ) {
                place = teamIndex + 1
              }

              return (
                <TableRow key={teamIndex}>
                  <TableCell align="center">
                    <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                      {place}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    {viewMode ? (
                      leaderboardElement.team.map((member) => (
                        <Box display="flex" alignItems="center" mt={1}>
                          <UserAvatar
                            user={member}
                            sx={{ backgroundColor: 'white' }}
                          />
                          <Typography
                            ml={1}
                            variant={viewMode ? 'h6' : 'subtitle1'}
                            style={{ fontWeight: 'normal' }}
                          >
                            {`${member.name} ${member.surname}`.substring(
                              0,
                              maxUserNameAndSurnameLength
                            ) +
                              (member.name.length + member.surname.length >
                              maxUserNameAndSurnameLength
                                ? '...'
                                : '')}
                          </Typography>
                        </Box>
                      ))
                    ) : (
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        {leaderboardElement.team.map((member) => (
                          <Link
                            to={`/users/${member.user_id}`}
                            key={member.user_id}
                            className={styles.reverseLink}
                          >
                            <UserAvatarWithName user={member} />
                          </Link>
                        ))}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                      {leaderboardElement.team[0].round_robin_data.wins}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                      {leaderboardElement.team[0].round_robin_data.draws}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                      {leaderboardElement.team[0].round_robin_data.loses}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant={viewMode ? 'h6' : 'subtitle1'}>
                      {
                        leaderboardElement.team[0].round_robin_data
                          .games_difference
                      }
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}
