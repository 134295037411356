import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { getUtcTime } from 'utils'

const presets = [
  { label: 'Last Month', sortLabel: '1m', value: 'last_month' },
  { label: 'Last 3 Months', sortLabel: '3m', value: 'last_3_months' },
  { label: 'Last 6 Months', sortLabel: '6m', value: 'last_6_months' },
  { label: 'Last Year', sortLabel: '1y', value: 'last_year' },
  { label: 'All Time', sortLabel: '', value: 'all_time' },
  { label: 'Custom', sortLabel: 'custom', value: 'custom' },
]

/* eslint-disable react/jsx-props-no-spreading */
export function OrganizationLeaderboardFilterDialog({
  open,
  onClose,
  onApply,
}) {
  const [preset, setPreset] = useState('last_month')
  const [beforeDate, setBeforeDate] = useState(null)
  const [afterDate, setAfterDate] = useState(null)
  const [orderBy, setOrderBy] = useState('medals')

  useEffect(() => {
    const today = getUtcTime()
    switch (preset) {
      case 'last_month':
        setBeforeDate(today)
        setAfterDate(today.subtract(1, 'month'))
        break
      case 'last_3_months':
        setBeforeDate(today)
        setAfterDate(today.subtract(3, 'months'))
        break
      case 'last_6_months':
        setBeforeDate(today)
        setAfterDate(today.subtract(6, 'months'))
        break
      case 'last_year':
        setBeforeDate(today)
        setAfterDate(today.subtract(1, 'year'))
        break
      case 'all_time':
        setBeforeDate(null)
        setAfterDate(null)
        break
      case 'custom':
        // Do not set any dates, allow user to pick
        break
      default:
        break
    }
  }, [preset])

  const handleApply = () => {
    const newFilters = {
      before_date: beforeDate === null ? null : beforeDate.format('DD.MM.YYYY'),
      after_date: afterDate === null ? null : afterDate.format('DD.MM.YYYY'),
      order_by: orderBy,
    }
    const presetLabel = presets.find((p) => p.value === preset)?.sortLabel || ''
    onApply(newFilters, presetLabel)
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Filter Leaderboard</DialogTitle>
      <DialogContent dividers>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                select
                label="Preset"
                value={preset}
                onChange={(e) => setPreset(e.target.value)}
                fullWidth
              >
                {presets.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {preset === 'custom' && (
              <>
                <Grid item xs={6}>
                  <DatePicker
                    label="Custom Start Date"
                    value={afterDate}
                    onChange={(date) => setAfterDate(date)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    label="Custom End Date"
                    value={beforeDate}
                    onChange={(date) => setBeforeDate(date)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                select
                label="Order By"
                value={orderBy}
                onChange={(e) => setOrderBy(e.target.value)}
                fullWidth
              >
                <MenuItem value="medals">Medals</MenuItem>
                <MenuItem value="americano_points">Americano Points</MenuItem>
                <MenuItem value="tournaments_played">
                  Tournaments Played
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleApply} variant="contained">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}
