import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  DialogActions,
} from '@mui/material'

export function MaxUsersSelector({
  defaultMaxUsers = 0,
  maxUsersOptions,
  teamsView = false,
  disabled = true,
  setValue,
}) {
  const [open, setOpen] = useState(false)
  const [selectedMaxUsers, setSelectedMaxUsers] = useState(defaultMaxUsers)

  useEffect(() => {
    setSelectedMaxUsers(defaultMaxUsers)
  }, [defaultMaxUsers])

  useEffect(() => {
    setValue('maxUsers', selectedMaxUsers)
  }, [selectedMaxUsers, setValue])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSelectMaxUsers = (value) => {
    setSelectedMaxUsers(value)
    setOpen(false)
  }

  const buttonText = teamsView
    ? `Max Teams: ${selectedMaxUsers / 2}`
    : `Max Players: ${selectedMaxUsers}`

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        fullWidth
        disabled={disabled}
      >
        {buttonText}
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{`Select Max ${
          teamsView ? 'Teams' : 'Players'
        }`}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {maxUsersOptions.map((value) => (
              <Grid
                item
                xs={4}
                key={value}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                <Button
                  variant="outlined"
                  onClick={() => handleSelectMaxUsers(Number(value))}
                  sx={{
                    width: '100%',
                    minHeight: '50px',
                    padding: '4px 8px',
                    borderColor: 'primary.main',
                    fontSize: '1.4rem',
                  }}
                >
                  {teamsView ? value / 2 : value}
                </Button>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
