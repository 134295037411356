import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { Button, FormControl, Grid, TextField, Typography } from '@mui/material'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'

import {
  useCreateTournament,
  useTournamentRoundNumber,
} from 'hooks/api/tournamentHooks'

import { useOrganization } from 'hooks/api/organizationHooks'

import { ResponseAlert } from 'components/layout/Alert/ResponseAlert'
import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import { CustomDatePicker, CustomTimePicker } from 'components/CustomPicker'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'
import { LocationBlock } from 'components/LocationBlock/LocationBlock'
import { LocationSelector } from 'components/LocationSelector/LocationSelector'
import { LocationCourtsSelector } from 'components/LocaitonCourts/LocationCourtsSelector'
import { MaxUsersSelector } from 'components/MaxUsersSelector/MaxUsersSelector'

import { getUtcTime } from 'utils'

/* eslint-disable react/jsx-props-no-spreading */
export function CreateRoundRobinTeam() {
  const tournamentType = 'round_robin_team'
  const navigate = useNavigate()
  const { themeMode } = useThemeSwitcher()

  const { organizationId } = useParams()
  const { data: organizationData } = useOrganization(organizationId)
  const { data: roundNumber } = useTournamentRoundNumber(tournamentType)

  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      startTime: getUtcTime(),
      endTime: getUtcTime(),
      location: organizationData?.location || null,
      locationId: organizationData?.location_id || null,
    },
  })

  useEffect(() => {
    if (organizationData) {
      setValue('location', organizationData.location || null)
      setValue('locationId', organizationData.location_id || null)
    }
  }, [organizationData, setValue])

  const [courts, setCourts] = useState([])

  const [errorMessage, setErrorMessage] = useState('')

  const { trigger: createAmericanoTeamTrigger } = useCreateTournament()

  const [responseAlert, setResponseAlert] = useState(null)

  useEffect(() => {
    setCourts([])
  }, [watch('locationId')])

  const handleCreateTournament = (data) => {
    createAmericanoTeamTrigger(
      {
        type: tournamentType,
        americano_max_score: Number(data.maxScore),
        max_users: Number(data.maxUsers),
        organization_id: Number(organizationId),
        location_id: Number(data.locationId),
        name: data.name,
        start_time: getUtcTime(data.startTime),
        end_time: getUtcTime(data.endTime),
        courts,
        description: data.description,
      },
      {
        onSuccess: (data) => navigate(`/tournament/${data.id}`),
        onError: (data) => setErrorMessage(data.info.error),
      }
    )
  }

  let singleCourtsNumber = 0
  let doubleCourtsNumber = 0

  for (let i = 0; i < courts.length; i += 1) {
    if (courts[i].size === 'single') {
      singleCourtsNumber += 1
    } else {
      doubleCourtsNumber += 1
    }
  }

  const defaultMaxUsers = singleCourtsNumber * 2 + doubleCourtsNumber * 4

  let courtsString = `${doubleCourtsNumber}courts`
  if (singleCourtsNumber > 0 && doubleCourtsNumber > 0) {
    courtsString += `_${singleCourtsNumber}singlecourts`
  }

  let maxUsersOptions = []

  if (roundNumber) {
    if (roundNumber.courts[courtsString]) {
      maxUsersOptions = Object.keys(roundNumber.courts[courtsString].players)
    }
  }

  return (
    <MainWrapper
      maxWidth="md"
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      {responseAlert && (
        <Grid container spacing={2}>
          <Grid item xs={12} mt={4} sx={{ textAlign: 'center' }}>
            <ResponseAlert
              type={responseAlert?.type}
              message={responseAlert?.message}
              onClose={() => setResponseAlert(null)}
            />
          </Grid>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        mt={{ xs: 2, md: 4 }}
        mb={2}
        sx={{ textAlign: 'center' }}
      >
        <Typography variant="h5">New Round Robin Tournament (Team)</Typography>
      </Grid>

      <FormControl
        component="form"
        onSubmit={handleSubmit(handleCreateTournament)}
      >
        {/* TODO: move to separate component */}
        <Grid container spacing={2} mb={4} alignItems="flex-start">
          <Grid item xs={12}>
            <TextField
              required
              style={{ width: '100%' }}
              error={!!errors.name}
              helperText={!!errors.name && 'Please, enter tournament name'}
              label="Tournament Name"
              {...register('name', { required: true })}
            />
          </Grid>

          <Grid item container spacing={2} xs={12} md={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12}>
                <CustomDatePicker
                  sx={{ width: '100%' }}
                  label="Tournament date *"
                  control={control}
                  name="startTime"
                  value={getUtcTime(watch('startTime'))}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTimePicker
                  sx={{ width: '100%' }}
                  label="Start Time *"
                  control={control}
                  name="startTime"
                  value={getUtcTime(watch('startTime'))}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTimePicker
                  sx={{ width: '100%' }}
                  label="End time *"
                  control={control}
                  name="endTime"
                  value={getUtcTime(watch('endTime'))}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>

          <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }} />
          <Grid item container spacing={2} xs={12} md={6}>
            <Grid item xs={12}>
              {organizationData?.has_permanent_location ? (
                <LocationBlock
                  location={watch('location')}
                  themeMode={themeMode}
                  withoutLink
                />
              ) : (
                <LocationSelector
                  themeMode={themeMode}
                  currentLocation={watch('location')}
                  onLocationSelect={(location) => {
                    setValue('locationId', location.id, {
                      shouldValidate: true,
                    })
                    setValue('location', location, { shouldValidate: true })
                    setValue('hasPermanentLocation', true, {
                      shouldValidate: true,
                    })
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }} />

          {(organizationData?.location || watch('location')) && (
            <Grid item xs={12}>
              <LocationCourtsSelector
                maxCourts={6}
                courts={
                  organizationData?.location
                    ? organizationData?.location.courts
                    : watch('location').courts
                }
                onCourtToggle={(court, isSelected) => {
                  if (isSelected) {
                    setCourts([...courts, court])
                  } else {
                    setCourts(courts.filter((c) => c.id !== court.id))
                  }
                }}
              />
            </Grid>
          )}

          <Grid item container spacing={2} xs={12}>
            <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
            <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center' }}>
              <MaxUsersSelector
                defaultMaxUsers={defaultMaxUsers}
                register={register}
                setValue={setValue}
                maxUsersOptions={maxUsersOptions}
                teamsView
                disabled={!watch('location') || !(courts.length > 0)}
              />
            </Grid>
            <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
          </Grid>

          <Grid item xs={12}>
            <TextField
              sx={{
                width: '100%',
                height: '100%',
                '& .MuiInputBase-root': {
                  height: '100%',
                  alignItems: 'start',
                },
              }}
              error={!!errors.description}
              label="Description"
              multiline
              {...register('description')}
              inputProps={{
                sx: {
                  maxHeight: '100%',
                },
              }}
              maxRows={10}
            />
          </Grid>

          <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
          <Grid item mt={2} xs={12} md={4}>
            <Button
              style={{ width: '100%' }}
              type="submit"
              disabled={courts.length <= 0 || watch('maxUsers') === 0}
              variant="contained"
            >
              Create
            </Button>
          </Grid>
          <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
        </Grid>
        {/* TODO: move to separate component */}
      </FormControl>
    </MainWrapper>
  )
}
