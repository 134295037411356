import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Button,
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'

import { useCurrentUser } from 'hooks/cookies'
import { useUpdateLocation } from 'hooks/api/locationHooks'

import { MainWrapper } from 'components/layout/MainWrapper/MainWrapper'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'

import { CustomTimePicker } from 'components/CustomPicker'
import { LocationCourts } from 'components/LocaitonCourts/LocationCourts'
import { CountryCitySelector } from 'components/CountryCitySelector/CountryCitySelector'
import { Map, LocationPicker } from 'components/Map/Map'

import { isAdmin, prepareCourtsForUpdate, getUtcTime } from 'utils'

/* eslint-disable react/jsx-props-no-spreading */
export function LocationBase({ locationData, locationLoading }) {
  const { themeMode } = useThemeSwitcher()

  const [user] = useCurrentUser()
  const admin = isAdmin(user)

  const [courts, setCourts] = useState([])
  const [locationDetails, setLocationDetails] = useState({})
  const [errorMessage, setErrorMessage] = useState('')

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      readOnly: true,

      name: locationData.name || '',
      address: locationData.address || '',
      country: locationData.country || '',
      city: locationData.city || '',
      openingTime: locationData.opening_time || Date.now(),
      closingTime: locationData.closing_time || Date.now(),
      latitude: locationData.latitude || 0,
      longitude: locationData.longitude || 0,
    },
  })

  const collectChanges = () => {
    const currentValues = {
      name: watch('name'),
      address: watch('address'),
      country: locationDetails.countryName,
      city: locationDetails.cityName,
      openingTime: watch('openingTime'),
      closingTime: watch('closingTime'),
      latitude: locationDetails.lat,
      longitude: locationDetails.lng,
      courts,
    }

    const changes = Object.keys(currentValues).reduce((acc, key) => {
      if (currentValues[key] !== locationData[key]) {
        acc[key] = currentValues[key]
      }
      return acc
    }, {})

    return changes
  }

  useEffect(() => {
    if (locationData && !locationLoading) {
      setLocationDetails({
        ...locationDetails,
        countryName: locationData.country,
        cityName: locationData.city,
        lat: locationData.latitude,
        lng: locationData.longitude,
        timezone: locationData.timezone,
      })
      setCourts(locationData.courts || [])
    }
  }, [locationData, locationLoading])

  const { trigger: updateLocationTrigger } = useUpdateLocation()

  const handleLocationUpdate = () => {
    const changes = collectChanges()
    if (changes.courts) {
      changes.courts = prepareCourtsForUpdate(courts)
    }

    updateLocationTrigger(
      {
        id: locationData.id,
        ...changes,
      },
      {
        onSuccess: () => {
          setValue('readOnly', true)
        },
        onError: (data) => setErrorMessage(data.info.error),
      }
    )
  }

  const handleEditClick = () => {
    setValue('readOnly', false)
  }

  const handleCancelClick = () => {
    setValue('readOnly', true)
  }

  return (
    <MainWrapper
      maxWidth="md"
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      <FormControl
        component="form"
        onSubmit={handleSubmit(handleLocationUpdate)}
        fullWidth
      >
        <Grid container spacing={2} mb={4} alignItems="flex-start">
          <Grid item xs={12} textAlign="center">
            <Typography variant="h5">
              {watch('readOnly') ? `${locationData?.name}` : 'Update location'}
            </Typography>
          </Grid>

          {!watch('readOnly') && (
            <Grid item xs={12}>
              <TextField
                required
                style={{ width: '100%' }}
                error={!!errors.name}
                label="Location name"
                readOnly={watch('readOnly')}
                defaultValue={locationData?.name}
                {...register('name', { required: true })}
              />
            </Grid>
          )}

          <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }} />
          <Grid item md={6} container spacing={2} alignItems="flex-start">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={6}>
                <CustomTimePicker
                  sx={{ width: '100%' }}
                  label="Opening Time *"
                  control={control}
                  name="openingTime"
                  value={getUtcTime(watch('openingTime'))}
                  readOnly={watch('readOnly')}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTimePicker
                  sx={{ width: '100%' }}
                  label="Closing Time *"
                  control={control}
                  name="closingTime"
                  value={getUtcTime(watch('closingTime'))}
                  readOnly={watch('readOnly')}
                />
              </Grid>
            </LocalizationProvider>

            {watch('readOnly') ? (
              <>
                <Grid item xs={12}>
                  <TextField
                    required
                    style={{ width: '100%' }}
                    error={!!errors.name}
                    label="Country"
                    readOnly
                    value={locationData?.country}
                    {...register('country', { required: true })}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    style={{ width: '100%' }}
                    error={!!errors.name}
                    label="City"
                    readOnly
                    value={locationData?.city}
                    {...register('city', { required: true })}
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={12}>
                <CountryCitySelector
                  setLocationDetails={setLocationDetails}
                  oldCountry={locationDetails.countryName}
                  oldCity={locationDetails.cityName}
                />
              </Grid>
            )}
          </Grid>
          <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }} />

          {watch('readOnly') ? (
            <Grid
              item
              container
              spacing={2}
              xs={12}
              sx={{ textAlign: 'center' }}
            >
              <Grid item xs={12}>
                <Map
                  locationDetails={locationDetails}
                  setLocationDetails={setLocationDetails}
                  themeMode={themeMode}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              container
              spacing={2}
              xs={12}
              sx={{ textAlign: 'center' }}
            >
              <Grid item xs={12}>
                <LocationPicker
                  locationDetails={locationDetails}
                  setLocationDetails={setLocationDetails}
                  themeMode={themeMode}
                />
              </Grid>
            </Grid>
          )}

          <Grid item container spacing={2} xs={12} sx={{ textAlign: 'center' }}>
            <Grid item xs={12}>
              <TextField
                required
                style={{ width: '100%' }}
                error={!!errors.address}
                label="Address"
                readOnly={watch('readOnly')}
                defaultValue={locationData?.address}
                {...register('address', { required: true })}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <LocationCourts
              courts={courts}
              setCourts={setCourts}
              readOnly={watch('readOnly')}
            />
          </Grid>

          {admin && watch('readOnly') && (
            <>
              <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
              <Grid item xs={12} md={4}>
                <Button
                  style={{ width: '100%' }}
                  type="button"
                  color="primary"
                  onClick={handleEditClick}
                  startIcon={<EditIcon />}
                  variant="contained"
                >
                  Edit
                </Button>
              </Grid>
              <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
            </>
          )}

          {admin && !watch('readOnly') && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }} />
              <Grid item xs={6} md={4}>
                <Button
                  style={{ width: '100%' }}
                  type="button"
                  onClick={handleCancelClick}
                  variant="contained"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6} md={4}>
                <Button
                  style={{ width: '100%' }}
                  type="submit"
                  color="success"
                  disabled={!Object.keys(collectChanges()).length}
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
              <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }} />
            </>
          )}
        </Grid>
      </FormControl>
    </MainWrapper>
  )
}
