import React, { useRef, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { QRCodeSVG } from 'qrcode.react'

import { useForm, FormProvider } from 'react-hook-form'

import {
  CardMedia,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  Tab,
  Typography,
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import InfoIcon from '@mui/icons-material/Info'
import EditIcon from '@mui/icons-material/Edit'
import ListAltIcon from '@mui/icons-material/ListAlt'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import CloseIcon from '@mui/icons-material/Close'

import {
  MainWrapper,
  MainWrapperViewMode,
} from 'components/layout/MainWrapper/MainWrapper'
import { RoundRobinLeaderboardTable } from 'components/LeaderboardTable/LeaderboardTable'
import { RoundsSchedule } from 'components/RoundsSchedule/RoundsSchedule'
import {
  TournamentRegisteredTeams,
  TournamentRegisteredWaitlistTeams,
  TournamentRegisteredLeftPlayers,
} from 'components/TournamentRegisteredPlayers/TournamentRegisteredPlayers'
import { useThemeSwitcher } from 'components/ThemeProvider/ThemeProvider'
import { TournamentInfo } from 'components/TournamentInfo/TournamentInfo'
import { UpdateTournamentInfo } from 'components/TournamentInfo/UpdateTournamentInfo'

import {
  useRoundRobinTeamLeaderboard,
  useUpdateTournament,
} from 'hooks/api/tournamentHooks'

import { useOrganization } from 'hooks/api/organizationHooks'

import { useResponsive } from 'hooks/responsive'

import { isAdmin, getUtcTime } from 'utils'
import { useCurrentUser } from 'hooks/cookies'

/* eslint-disable react/jsx-props-no-spreading */
export function RoundRobinTeamFinished({ data: tournamentData, mutate }) {
  const { isScreenMdOrMore } = useResponsive()

  const { themeMode } = useThemeSwitcher()
  const currentUrl = window.location.href

  const { data: organizationData } = useOrganization(
    tournamentData?.organization_id
  )

  const [currentUser] = useCurrentUser()

  const admin = isAdmin(currentUser)
  const organizationAdmin =
    currentUser?.administered_organizations?.data?.length >= 1
  const adminOrOrganizationAdmin = admin || organizationAdmin

  const [tabNumber, setTabValue] = useState('1')

  const initialAddressIndexRef = useRef(0)
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0)

  const [errorMessage, setErrorMessage] = useState('')

  const tournamentAddressId = tournamentData?.address?.id

  useEffect(() => {
    if (organizationData?.addresses && tournamentAddressId) {
      const addressIndex = organizationData.addresses.findIndex(
        (address) => address.id === tournamentAddressId
      )

      setSelectedAddressIndex(addressIndex >= 0 ? addressIndex : 0)
      initialAddressIndexRef.current = addressIndex >= 0 ? addressIndex : 0
    }
  }, [organizationData, tournamentAddressId])

  const {
    organization_id,
    teams,
    waitlist_teams,
    left_users,
    schedule,
    courts,
  } = tournamentData

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      organization_name: tournamentData?.organization_name || '',
      readOnly: true,
      type: tournamentData?.type || 'americano_team',
      tournamentName: tournamentData?.name || '',
      americanoMaxScore: tournamentData?.americano_max_score || 0,
      startTime: tournamentData?.start_time
        ? getUtcTime(tournamentData?.start_time)
        : getUtcTime(Date.now()),
      endTime: tournamentData?.end_time
        ? getUtcTime(tournamentData?.end_time)
        : getUtcTime(Date.now()),
      description: tournamentData?.description || '',
      maxUsers: tournamentData?.max_users || 0,
      cycleCount: tournamentData?.cycle_count || 1,
    },
  })

  const collectChanges = () => {
    const currentValues = {
      name: watch('name'),
      start_time: watch('startTime'),
      end_time: watch('endTime'),
      description: watch('description'),
    }

    const changes = Object.keys(currentValues).reduce((acc, key) => {
      if (currentValues[key] !== tournamentData[key]) {
        acc[key] = currentValues[key]
      }
      return acc
    }, {})

    return changes
  }

  const handleChangeTab = (event, tabNumber) => {
    setTabValue(tabNumber)
  }

  const { tournamentId } = useParams()
  const tournamentIdInt = parseInt(tournamentId, 10)
  const {
    data: tournamentLeaderboardData,
    loading: tournamentLeaderboardLoading,
  } = useRoundRobinTeamLeaderboard(tournamentId)
  const { trigger: updateTournamentTrigger } = useUpdateTournament()

  const [isViewMode, setIsViewMode] = useState(() => {
    const savedViewMode = localStorage.getItem('isViewMode')
    return savedViewMode === 'true'
  })
  const [isFullScreen, setIsFullScreen] = useState(false)

  useEffect(() => {
    localStorage.setItem('isViewMode', isViewMode.toString())
  }, [isViewMode])

  const handleToggleFullScreen = () => {
    if (!isFullScreen) {
      document.documentElement
        .requestFullscreen()
        .then(() => {
          setIsFullScreen(true)
        })
        .catch((err) => {
          console.error(
            'Error attempting to enable full-screen mode:',
            err.message
          )
        })
    } else {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullScreen(false)
        })
        .catch((err) => {
          console.error(
            'Error attempting to exit full-screen mode:',
            err.message
          )
        })
    }
  }

  const toggleViewMode = () => {
    setIsViewMode((prevMode) => !prevMode)
    handleToggleFullScreen()
  }

  const handleUpdateTournament = () => {
    const changes = collectChanges()

    updateTournamentTrigger(
      {
        id: Number(tournamentId),
        ...changes,
      },
      {
        onSuccess: () => {
          mutate()
          setValue('readOnly', true)
        },
        onError: (data) => setErrorMessage(data.info),
      }
    )
  }

  const handleCancelUpdate = () => {
    reset()
  }

  const handleAddressChange = (event) => {
    setSelectedAddressIndex(event.target.value)
  }

  if (tournamentLeaderboardLoading) {
    return <MainWrapper>loading...</MainWrapper>
  }

  if (tournamentLeaderboardData) {
    return isViewMode ? (
      <MainWrapperViewMode>
        <Grid
          container
          alignItems="center"
          spacing={2}
          sx={{ position: 'relative' }}
        >
          <Grid
            container
            alignItems="center"
            spacing={2}
            sx={{ position: 'relative' }}
          >
            <Grid
              item
              xs={4}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <Box ml={4}>
                <QRCodeSVG value={currentUrl} size={100} />
              </Box>
            </Grid>

            <Grid
              item
              xs={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CardMedia
                  component="img"
                  image={
                    themeMode === 'dark'
                      ? '/logo_dark_theme.png'
                      : '/logo_dark_theme.png'
                  }
                  alt="logo"
                  sx={{ maxWidth: '700px', height: 'auto', ml: 2 }}
                />
                <CardMedia
                  component="img"
                  image={
                    themeMode === 'dark'
                      ? '/mascot_dark_theme.png'
                      : '/mascot_light_theme.png'
                  }
                  alt="mascot"
                  sx={{ maxWidth: '120px', height: 'auto', ml: 2 }}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={4}
              pr={4}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <CloseIcon
                onClick={() => toggleViewMode()}
                sx={{ cursor: 'pointer' }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={2} alignItems="flex-start">
            <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
            <Grid item xs={12} md={4} container spacing={2}>
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography variant="h4">{tournamentData?.name}</Typography>
              </Grid>

              <Grid item xs={12} mt={4}>
                <RoundRobinLeaderboardTable
                  leaderboard={tournamentLeaderboardData}
                  themeMode={themeMode}
                  viewMode
                  onlyPoints
                />
              </Grid>
            </Grid>
            <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} />
          </Grid>
        </Grid>
      </MainWrapperViewMode>
    ) : (
      <MainWrapper
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      >
        <FormProvider
          register={register}
          handleSubmit={handleSubmit}
          control={control}
          formState={{ errors }}
          watch={watch}
          reset={reset}
          setValue={setValue}
        >
          <FormControl
            component="form"
            onSubmit={handleSubmit(handleUpdateTournament)}
            fullWidth
          >
            <Grid item xs={12} mt={4} sx={{ textAlign: 'center' }}>
              <Typography variant="h5">{watch('tournamentName')}</Typography>
            </Grid>

            <Container maxWidth="md" sx={{ padding: 0 }}>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabNumber}>
                  <Container
                    sx={{
                      '@media (min-width: 0px)': {
                        paddingLeft: 0,
                        paddingRight: 0,
                      },
                    }}
                  >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChangeTab}>
                        <Tab
                          label="Leaderboard"
                          value="1"
                          sx={{ paddingLeft: 0 }}
                          icon={<EmojiEventsIcon />}
                          iconPosition="start"
                        />
                        <Tab
                          label="Rounds"
                          value="2"
                          icon={<ListAltIcon />}
                          iconPosition="start"
                        />
                        <Tab
                          label="Info"
                          value="3"
                          icon={<InfoIcon />}
                          iconPosition="start"
                        />
                      </TabList>
                    </Box>
                  </Container>

                  <TabPanel value="1" sx={{ padding: 0 }}>
                    <Grid container spacing={2} mb={4} mt={{ xs: 0, md: 0 }}>
                      <Grid
                        item
                        container
                        spacing={2}
                        xs={12}
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Grid
                          item
                          md={5}
                          sx={{ display: { xs: 'none', md: 'block' } }}
                        />
                        <Grid
                          item
                          md={2}
                          sx={{ display: { xs: 'none', md: 'block' } }}
                        >
                          <Button
                            onClick={toggleViewMode}
                            variant="contained"
                            style={{ width: '100%' }}
                          >
                            TV Mode
                          </Button>
                        </Grid>
                        <Grid
                          item
                          md={5}
                          sx={{ display: { xs: 'none', md: 'block' } }}
                        />
                      </Grid>
                      <Grid item xs={12} mt={{ xs: 0, md: 0 }}>
                        <RoundRobinLeaderboardTable
                          leaderboard={tournamentLeaderboardData}
                          themeMode={themeMode}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>

                  <TabPanel value="2" sx={{ padding: 0 }}>
                    {isScreenMdOrMore && !adminOrOrganizationAdmin && (
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        mt={1}
                      >
                        <Button onClick={toggleViewMode} variant="contained">
                          Switch to Fullscreen view
                        </Button>
                      </Grid>
                    )}

                    <Grid container spacing={2} mt={0}>
                      <Grid item xs={12}>
                        {schedule?.rounds?.map((round, idx) => (
                          <RoundsSchedule
                            key={idx}
                            organizationCourts={courts}
                            round={round}
                            roundNumber={idx}
                          />
                        ))}
                      </Grid>
                    </Grid>
                  </TabPanel>

                  <TabPanel value="3" sx={{ padding: 0 }}>
                    {watch('readOnly') ? (
                      <Grid container spacing={2} mb={4}>
                        <Grid item xs={12} mt={2}>
                          <TournamentInfo
                            tournamentData={tournamentData}
                            organizationData={organizationData}
                            themeMode={themeMode}
                          />
                        </Grid>

                        {adminOrOrganizationAdmin && (
                          <>
                            <Grid
                              item
                              md={4}
                              sx={{ display: { xs: 'none', md: 'block' } }}
                            />
                            <Grid item xs={12} md={4}>
                              <Button
                                fullWidth
                                onClick={() => setValue('readOnly', false)}
                                type="button"
                                startIcon={<EditIcon />}
                                variant="contained"
                              >
                                Edit
                              </Button>
                            </Grid>
                            <Grid
                              item
                              md={4}
                              sx={{ display: { xs: 'none', md: 'block' } }}
                            />
                          </>
                        )}

                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                          <Typography variant="h5">Registered Teams</Typography>
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <TournamentRegisteredTeams
                            onlySwapActions
                            playerCanInteract={false}
                            teams={teams}
                            waitlistTeams={waitlist_teams}
                            maxTeams={teams.length}
                            isAdmin={adminOrOrganizationAdmin}
                            currentUser={currentUser}
                            tournamentId={tournamentIdInt}
                            organizationId={organization_id}
                            exceptionIds={[
                              ...teams.flatMap((team) =>
                                team.users.map((u) => u.id)
                              ),
                              ...waitlist_teams.flatMap((team) =>
                                team.users.map((u) => u.id)
                              ),
                            ]}
                            themeMode={themeMode}
                          />
                        </Grid>

                        {waitlist_teams?.length > 0 && (
                          <>
                            <Grid
                              item
                              xs={12}
                              mt={1}
                              sx={{ textAlign: 'center' }}
                            >
                              <Typography variant="h5">Waitlist</Typography>
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <TournamentRegisteredWaitlistTeams
                                readOnly
                                waitlistTeams={waitlist_teams}
                                isAdmin={adminOrOrganizationAdmin}
                                currentUser={currentUser}
                                themeMode={themeMode}
                              />
                            </Grid>
                          </>
                        )}

                        {left_users && left_users.length > 0 && (
                          <>
                            <Grid
                              item
                              xs={12}
                              mt={1}
                              sx={{ textAlign: 'center' }}
                            >
                              <Typography variant="h5">Players Left</Typography>
                            </Grid>

                            <Grid item xs={12} md={12}>
                              <TournamentRegisteredLeftPlayers
                                leftPlayers={left_users}
                                themeMode={themeMode}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    ) : (
                      <Grid container spacing={2} mb={4}>
                        <Grid item xs={12} mt={2}>
                          <UpdateTournamentInfo
                            organizationData={organizationData}
                            selectedAddressIndex={selectedAddressIndex}
                            handleAddressChange={handleAddressChange}
                            themeMode={themeMode}
                            isAdmin={adminOrOrganizationAdmin}
                          />
                        </Grid>

                        {adminOrOrganizationAdmin && (
                          <>
                            <Grid item xs={2} />
                            <Grid item xs={4}>
                              <Button
                                fullWidth
                                onClick={handleCancelUpdate}
                                type="button"
                                variant="contained"
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid item xs={4}>
                              <Button
                                fullWidth
                                type="submit"
                                disabled={!Object.keys(collectChanges()).length}
                                variant="contained"
                              >
                                Submit
                              </Button>
                            </Grid>
                            <Grid item xs={2} />
                          </>
                        )}
                      </Grid>
                    )}
                  </TabPanel>
                </TabContext>
              </Box>
            </Container>
          </FormControl>
        </FormProvider>
      </MainWrapper>
    )
  }
}
