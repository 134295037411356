import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { getFetcher, postFetcher, putFetcher, deleteFetcher } from './fetchers'

export function useOrganizations({
  adminId = null,
  has_permanent_location = null,
  limit = 10,
  offset = 0,
}) {
  const params = { limit, offset }
  if (adminId) {
    params.admin_user_id = adminId
  }

  if (has_permanent_location !== null) {
    params.has_permanent_location = has_permanent_location
  }

  const searchParams = new URLSearchParams(params)
  return useSWR(`/organization?${searchParams}`, getFetcher)
}

export function useOrganization(organizationId) {
  return useSWR(`/organization/${organizationId}`, getFetcher)
}

export function useOrganizationStatistic({
  organizationId,
  limit = 10,
  offset = 0,
  after_date = null,
  before_date = null,
  order_by = null,
}) {
  const params = { limit, offset };
  if (order_by != null) {
    params.order_by = order_by;
  }
  if (after_date != null) {
    params.after_date = after_date;
  }
  if (before_date != null) {
    params.before_date = before_date;
  }

  const searchParams = new URLSearchParams(params);
  const key = `/organization/${organizationId}/statistic?${searchParams}`;

  const { data, error, mutate } = useSWR(key, getFetcher);

  const refetch = async (newParams = {}) => {
    const updatedParams = { ...params, ...newParams };
    const updatedSearchParams = new URLSearchParams(updatedParams);
    const updatedKey = `/organization/${organizationId}/statistic?${updatedSearchParams}`;
    const newData = await getFetcher(updatedKey);
    mutate(updatedKey, newData, false);
    return newData;
  };

  return { data, error, refetch };
}

export function useCreateOrganization() {
  return useSWRMutation('/organization', postFetcher)
}

export function useUpdateOrganization() {
  return useSWRMutation('/organization/', putFetcher)
}

export function useCreateOrganizationAddress() {
  return useSWRMutation('/organization/address', postFetcher)
}

export function useUpdateOrganizationAddress() {
  return useSWRMutation('/organization/address', putFetcher)
}

export function useDeleteOrganizationAddress() {
  return useSWRMutation('/organization/address', deleteFetcher)
}

export function usePseudoDeleteOrganizationAddress() {
  return useSWRMutation('/organization/address/pseudo_delete', deleteFetcher)
}

export function useCanOrganizatorUpdateUser({ userId }) {
  return useSWRMutation(`/organization/member/can_update/${userId}`, getFetcher)
}

export function useUpdateOrganizationMember() {
  return useSWRMutation('/organization/member', putFetcher)
}
