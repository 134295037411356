import React, { useState, useEffect } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material'

/* eslint-disable react/jsx-props-no-spreading */
export function PointsToPlaySelector({ setValue, disabled=true }) {
  const [open, setOpen] = useState(false)
  const [selectedPoints, setSelectedPoints] = useState(16)
  const [customValue, setCustomValue] = useState('')
  const [isCustom, setIsCustom] = useState(false)

  const options = [12, 16, 21, 28, 32]

  useEffect(() => {
    setValue('maxScore', selectedPoints)
  }, [selectedPoints, setValue])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setIsCustom(false)
    setCustomValue('')
  }

  const handleSelectPoints = (value) => {
    setSelectedPoints(value)
    setOpen(false)
    setIsCustom(false)
    setCustomValue('')
  }

  const handleCustomSelect = () => {
    const value = parseInt(customValue, 10)
    if (!Number.isNaN(value) && value > 0) {
      setSelectedPoints(value)
      setOpen(false)
      setIsCustom(false)
      setCustomValue('')
    }
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        fullWidth
        disabled={disabled}
      >
        {`Points to Play: ${selectedPoints}`}
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Select Points to Play</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {options.map((value) => (
              <Grid item xs={4} key={value}>
                <Button
                  variant="outlined"
                  onClick={() => handleSelectPoints(value)}
                  sx={{
                    width: '100%',
                    minHeight: '50px',
                    padding: '4px 8px',
                    borderColor: 'primary.main',
                    fontSize: '1.4rem',
                  }}
                >
                  {value}
                </Button>
              </Grid>
            ))}
            <Grid item xs={4}>
              <Button
                variant="outlined"
                onClick={() => setIsCustom(true)}
                sx={{
                  width: '100%',
                  minHeight: '50px',
                  padding: '4px 8px',
                  borderColor: 'primary.main',
                }}
              >
                Custom
              </Button>
            </Grid>
            {isCustom && (
              <Grid item xs={12}>
                <TextField
                  label="Custom Points"
                  value={customValue}
                  onChange={(e) => setCustomValue(e.target.value)}
                  type="number"
                  fullWidth
                  sx={{ mt: 2 }}
                />
                <Button
                  fullWidth
                  variant="contained"
                  onClick={handleCustomSelect}
                  sx={{ mt: 2 }}
                >
                  Set Custom Value
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
